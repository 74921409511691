import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, onSnapshot, addDoc, updateDoc, doc } from "firebase/firestore";
import { db, storage } from '../../../../../firebaseConfig';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage";


const EditProd = ({idproduct}) => {

  const [categlist, setCategList] = useState([]);
  const [image, setImage] = useState([]);
  const [modalimgpreviewvis, setmodalimgpreviewvis] = useState("none");
  const [imageList, setImgList] = useState([]);
  const [selectedImg, setSelectedImg] = useState([]);
  const [domImg, setDomImg] = useState(null);
  const [docid, setdocid] = useState(null);

  const handleChange = e => {
    if (e.target.files[0]) {

      const arr = [{image: e.target.files[0], url: URL.createObjectURL(e.target.files[0])}];
      setSelectedImg(arr[0]);
      /*const previewUrl = URL.createObjectURL(e.target.files[0]);
      setImage(previewUrl);*/
      setmodalimgpreviewvis("flex");
    }
  };

  const closeModalImgPreview = () => {
    setmodalimgpreviewvis("none");
  }

  const addImg = (img) =>{
    if(img){
      setImage([...image, img]);
      const arrlist = [];
      arrlist.push(img);
      const imguri = URL.createObjectURL(arrlist[0]);
      setImgList([...imageList, imguri]);
      closeModalImgPreview();
    }
  }

  const deleteImg = async(index) =>{

    if(window.confirm('Você tem certeza que deseja excluir essa imagem?')){

        var deleteBD = false;

        if(imageList[index].substring(0,4) != "blob"){
            deleteBD = true;
        }

        if(deleteBD){
            try{
                const storageRef = ref(storage, imageList[index]);
                await deleteObject(storageRef);

                alert("Imagem Deletada Com Sucesso");

                // atualizando fotos no BD

                const newArrFotos = imageList;
                newArrFotos.splice(index, 1);

                const docRef = doc(db, "product", docid);
                await updateDoc(docRef, {
                    fotos: newArrFotos
                });

            }catch(error){
                alert("Ocorreu um problema ao deletar a imagem do cloud");
            }
            
        }

        const arrDelete = imageList;
        arrDelete.splice(index, 1);
    }

  }

  const uploadImage = async(uri) =>{
        if (!uri) return;

        // Criar um nome de arquivo único
        const filename = uri.substring(uri.lastIndexOf('/') + 1);
        const response = await fetch(uri);
        const blob = await response.blob();

        // Referência ao arquivo no Firebase Storage
        const storageRef = ref(storage, filename);

        // Upload do arquivo para o Firebase Storage
        await uploadBytes(storageRef, blob);

        // Obter a URL de download
        const url = await getDownloadURL(storageRef);

        return url;
    }

  const [nameproduct, setNameProduct] = useState(null);
  const [priceproduct, setPriceProduct] = useState(null);
  const [pricePromoProduct, setPricePromoProduct] = useState(null);
  const [marcaproduct, setMarcaProduct] = useState(null);
  const [gtinproduct, setGtinProduct] = useState(null);
  const [cartegoriaproduct, setCategoriaProduct] = useState(null);
  const [textproduct, setTextProduct] = useState(null);
  const [imagensproduct, setImagesProducts] = useState(null);
  const [seokey, setSeoKey] = useState(null);
  const [prodDest, setProdDest] = useState(false);
  const [minidesc, setminidesc] = useState(null);


  const defineNameProd = (e) =>{
    setNameProduct(e.target.value);
  }

  const definePriceProd = (e) =>{
    setPriceProduct(e.target.value);
  }

  const definePricePromoProd = (e) =>{
    setPricePromoProduct(e.target.value);
  }

  const defineMarcaeProd = (e) =>{
    setMarcaProduct(e.target.value);
  }

  const defineGtinProd = (e) =>{
    setGtinProduct(e.target.value);
  }

  const defineCategProd = (e) =>{
    setCategoriaProduct(e.target.value);
  }

  const defineTextProd = (e) =>{
    setTextProduct(e.target.value);
  }

  const defineSeoProd = (e) =>{
    setSeoKey(e.target.value);
  }

  const defineProdDest = (e) => {
    if(e.target.checked){
        setProdDest(true);
    }else{
        setProdDest(false);
    }
  }

  const defineminidesc = (e) =>{
    setminidesc(e.target.value);
  }

  const saveProduct = async() =>{
      if(nameproduct == ""){
        alert("Preencha o campo Nome do producto");
      }else if(marcaproduct == ""){
        alert("Preencha o campo Marca do producto");
      }else if(cartegoriaproduct == ""){
        alert("Preencha o campo Categoria do producto");
      }else if(textproduct == ""){
        alert("Preencha o campo Descrição do producto");
      }else if(seokey == ""){
        alert("Preencha o campo Palavras Chaves");
      }else{

        // realizando o upload das imagens

        const imagesURL = [];

        const newImgList = imageList;


        for(var i =0; i < imageList.length; i++){
          
          var uri = imageList[i];

          if(uri.substring(0,4) == "blob"){
            const filename = uri.substring(uri.lastIndexOf('/') + 1);
            const response = await fetch(uri);
            const blob = await response.blob();

            // Referência ao arquivo no Firebase Storage
            const storageRef = ref(storage, filename);

            // Upload do arquivo para o Firebase Storage
            await uploadBytes(storageRef, blob);

            // Obter a URL de download
            const url = await getDownloadURL(storageRef);

            imagesURL.push(url);

            newImgList.splice(i, 1);

          }

        }

        
        newImgList.push(imagesURL[0]);
        

        // atualizando o produto no BD

        const docRef = doc(db, "product", docid);
        await updateDoc(docRef, {
            name: nameproduct,
            price: priceproduct,
            pricePromo: pricePromoProduct,
            marca: marcaproduct,
            gtin: gtinproduct,
            category: cartegoriaproduct,
            desc: textproduct,
            seo: seokey,
            fotos: newImgList
        });

        alert("Produto Cadastrado Com Sucesso!");

      }
  }

  useEffect(() => {
    

    // pegando dados do produto

    const qprods = query(collection(db, "product"), where("id", "==", idproduct));
    const unsubscribe = onSnapshot(qprods, (querySnapshot) => {
    const products = [];

    querySnapshot.forEach((doc) => {
        products.push(doc.data());
        setdocid(doc.id);
    });
    
    const product = products[0];

    setNameProduct(product.name);
    setPriceProduct(product.price);
    setPricePromoProduct(product.pricePromo);
    setMarcaProduct(product.marca);
    setGtinProduct(product.gtin);
    setCategoriaProduct(product.category);
    setTextProduct(product.desc);
    setSeoKey(product.seo);

    setImgList(product.fotos);
    setProdDest(product.destaque);

    });
  
  }, []);

  const q = query(collection(db, "category"), where("loja", "==", "ktx"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const categs = [];
        querySnapshot.forEach((doc) => {
            categs.push(doc.data());
        });


        if(categs.length == 0){

        }else{
            setCategList(categs);
        }

        });

  return <div className='container_painel_admin'>
    
    <h1>Editar Produto</h1>

    <div className='row'>
      <div className='col-md-4'>
        <label for="nomeprod">Nome do Produto</label>
        <input type="text" id="nomeprod" className='form-control' onChange={defineNameProd} value={nameproduct}/>
      </div>

      <div className='col-md-4'>
        <label for="priceprod">Preço</label>
        <input type="number" id="priceprod" className='form-control' onChange={definePriceProd} value={priceproduct}/>
      </div>

      <div className='col-md-4'>
        <label for="priceprodpromo">Preço Promocional</label>
        <input type="number" id="priceprodpromo" className='form-control' onChange={definePricePromoProd} value={pricePromoProduct}/>
      </div>

      <div className='col-md-4'>
        <label for="marcaprod">Marca do Produto</label>
        <input type="text" id="marcaprod" className='form-control' onChange={defineMarcaeProd} value={marcaproduct}/>
      </div>

      <div className='col-md-4'>
        <label for="gtinprod">GTIN Produto</label>
        <input type="text" id="gtinprod" className='form-control' onChange={defineGtinProd} value={gtinproduct}/>
      </div>

      <div className='col-md-4'>
        <label for="categprod">Categoria Produto</label>
        <select className="form-select" aria-label="Default select example" onChange={defineCategProd}>
          <option selected>Selecione a categoria</option>
          {categlist.map((item, index) => (
            <option key={index} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </div>


    <div className='row'>

      <div className='col-md-12'>
        <label for='descprod'>Descrição do Produto</label>
        <textarea className='form-control' onChange={defineTextProd} value={textproduct} style={{height: 200}}></textarea>
      </div>

      <div className='col-md-12'>
        <label for='descprod'>Mini Descrição</label>
        <textarea className='form-control' onChange={defineminidesc} value={minidesc}></textarea>
      </div>

    </div>

    <p style={{marginTop: 10}}>Galeria de Imagens</p>
    <div className='row'>
      <div className='col-md-4'>
        <input style={{marginTop: 10}} type='file' className='inputimg form-control' accept="image/png, image/gif, image/jpeg" onChange={handleChange}/>
      </div>
      <div className='col-md-8 slidegalericad'>
          {imageList.map((item, index) => (
            <div className='container_image_galeriacad'>
              <button className=' btn btn-danger btn_remove_img_cad' onClick={() => deleteImg(index)}>Remover</button>
              <img src={item}  className='img_cad_galeria' />
            </div>
          ))}
      </div>
    </div>

    <div className='row' style={{marginTop: 10}}>
      <div className='col-md-4'>
        <label for="prodchaves">Palavras Chaves</label>
        <input type="text" id="prodchaves" className='form-control' onChange={defineSeoProd} value={seokey}/>
      </div>

      <div className='col-md-4' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <input class="form-check-input" type="checkbox" value="" id="proddestaque" checked={prodDest} onChange={defineProdDest}/>
        <label class="form-check-label" for="proddestaque" style={{marginLeft: 10, fontSize: 16, fontWeight: 'bold'}}>
            Produto Destaque
        </label>
      </div>

      <div className='col-md-4' style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
        <button className='btn btn-primary' onClick={saveProduct}>Salvar</button>
      </div>
    </div>


    <div className='modal_image_preview' style={{display: modalimgpreviewvis}}>
      <div className='modal_image_prevew_content'>
        <img src={selectedImg.url} width="60%"/>
        <section className='contentbtnmodalimgpreview'>
          <button className='btn btn-primary' style={{marginTop: 10}} onClick={() => addImg(selectedImg.image)}>Adicionar Imagem</button>
          <button className='btn btn-danger' style={{marginTop: 10}} onClick={closeModalImgPreview}>Fechar</button>
        </section>
        
      </div>
    </div>

  </div>;
};

export default EditProd;