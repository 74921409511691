import React, { useEffect, useState } from 'react';
import { auth } from '../../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import Login from './componentes/login';
import Admin from './componentes/admin';


const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [content, setContent] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        console.log('logado');
        const usercorrent = auth.currentUser;

        setContent(<Admin/>);
       
      } else {
        setUser(null);
        setContent(<Login/>);
      }
    });

    return unsubscribe;
  }, []);

  return <div>
      {content}
  </div>;
};

export default Dashboard;