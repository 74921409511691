import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, onSnapshot, addDoc } from "firebase/firestore";
import { db, storage } from '../../../../../firebaseConfig';
import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import imageCompression from 'browser-image-compression';


const AddProd = () => {

  const [categlist, setCategList] = useState([]);
  const [image, setImage] = useState([]);
  const [modalimgpreviewvis, setmodalimgpreviewvis] = useState("none");
  const [imageList, setImgList] = useState([]);
  const [selectedImg, setSelectedImg] = useState([]);
  const [domImg, setDomImg] = useState(null);
  const [prodDest, setProdDest] = useState(false);

  const handleChange = e => {
    if (e.target.files[0]) {

      const arr = [{image: e.target.files[0], url: URL.createObjectURL(e.target.files[0])}];
      setSelectedImg(arr[0]);
      /*const previewUrl = URL.createObjectURL(e.target.files[0]);
      setImage(previewUrl);*/
      setmodalimgpreviewvis("flex");
    }
  };

  const closeModalImgPreview = () => {
    setmodalimgpreviewvis("none");
  }

  const addImg = (img) =>{
    if(img){
      setImage([...image, img]);
      const arrlist = [];
      arrlist.push(img);
      const imguri = URL.createObjectURL(arrlist[0]);
      setImgList([...imageList, imguri]);
      closeModalImgPreview();
    }
  }

  const deleteImg = (index) =>{
    alert(index);
  }

  const uploadImage = async(uri) =>{
        if (!uri) return;

        // Criar um nome de arquivo único
        const filename = uri.substring(uri.lastIndexOf('/') + 1);
        const response = await fetch(uri);
        const blob = await response.blob();

        // Referência ao arquivo no Firebase Storage
        const storageRef = ref(storage, filename);

        // Upload do arquivo para o Firebase Storage
        await uploadBytes(storageRef, blob);

        // Obter a URL de download
        const url = await getDownloadURL(storageRef);

        return url;
    }

  const [nameproduct, setNameProduct] = useState(null);
  const [priceproduct, setPriceProduct] = useState(null);
  const [pricePromoProduct, setPricePromoProduct] = useState(null);
  const [marcaproduct, setMarcaProduct] = useState(null);
  const [gtinproduct, setGtinProduct] = useState(null);
  const [cartegoriaproduct, setCategoriaProduct] = useState(null);
  const [textproduct, setTextProduct] = useState(null);
  const [imagensproduct, setImagesProducts] = useState(null);
  const [seokey, setSeoKey] = useState(null);
  const [minidesc, setminidesc] = useState(null);

  const defineNameProd = (e) =>{
    setNameProduct(e.target.value);
  }

  const definePriceProd = (e) =>{
    setPriceProduct(e.target.value);
  }

  const definePricePromoProd = (e) =>{
    setPricePromoProduct(e.target.value);
  }

  const defineMarcaeProd = (e) =>{
    setMarcaProduct(e.target.value);
  }

  const defineGtinProd = (e) =>{
    setGtinProduct(e.target.value);
  }

  const defineCategProd = (e) =>{
    setCategoriaProduct(e.target.value);
  }

  const defineTextProd = (e) =>{
    setTextProduct(e.target.value);
  }

  const defineminidesc = (e) =>{
    setminidesc(e.target.value);
  }

  const defineSeoProd = (e) =>{
    setSeoKey(e.target.value);
  }

  const defineProdDest = (e) => {
    if(e.target.checked){
        setProdDest(true);
    }else{
        setProdDest(false);
    }
  }

  const saveProduct = async() =>{
        function gerarStringUnica() {
            // Obtém o timestamp atual em milissegundos
            const timestamp = Date.now().toString(36); // Converte o timestamp para uma string em base 36 (0-9a-z)
            
            // Gera uma string aleatória de letras
            const letras = 'abcdefghijklmnopqrstuvwxyz';
            let stringAleatoria = '';
            for (let i = 0; i < 5; i++) { // Gera uma string de 5 letras aleatórias
                stringAleatoria += letras[Math.floor(Math.random() * letras.length)];
            }
            
            // Combina o timestamp com a string aleatória
            const stringUnica = timestamp + stringAleatoria;
            
            return stringUnica;
        }


      if(nameproduct == ""){
        alert("Preencha o campo Nome do producto");
      }else if(marcaproduct == ""){
        alert("Preencha o campo Marca do producto");
      }else if(cartegoriaproduct == ""){
        alert("Preencha o campo Categoria do producto");
      }else if(textproduct == ""){
        alert("Preencha o campo Descrição do producto");
      }else if(seokey == ""){
        alert("Preencha o campo Palavras Chaves");
      }else{

        // realizando o upload das imagens

        const imagesURL = [];

        for(var i =0; i < image.length; i++){

          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 400,
            useWebWorker: true,
          };

          const compressedFile = await imageCompression(image[i], options);

          var uri = URL.createObjectURL(compressedFile);

          const filename = uri.substring(uri.lastIndexOf('/') + 1);
          const response = await fetch(uri);
          const blob = await response.blob();

          // Referência ao arquivo no Firebase Storage
          const storageRef = ref(storage, filename);

          // Upload do arquivo para o Firebase Storage
          await uploadBytes(storageRef, blob);

          // Obter a URL de download
          const url = await getDownloadURL(storageRef);

          imagesURL.push(url);
        }

        // salvando o produto no BD

        const docRef = await addDoc(collection(db, "product"), {
          id: gerarStringUnica(),
          name: nameproduct,
          price: priceproduct,
          pricePromo: pricePromoProduct,
          marca: marcaproduct,
          gtin: gtinproduct,
          category: cartegoriaproduct,
          desc: textproduct,
          seo: seokey,
          fotos: imagesURL,
          destaque: prodDest,
          minidesc: minidesc
        });

        console.log("Document written with ID: ", docRef.id);

        alert("Produto Cadastrado Com Sucesso!");

      }
  }

  useEffect(() => {
  
  }, []);

  const q = query(collection(db, "category"), where("loja", "==", "ktx"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const categs = [];
        querySnapshot.forEach((doc) => {
            categs.push(doc.data());
        });


        if(categs.length == 0){

        }else{
            setCategList(categs);
        }

        });

  return <div className='container_painel_admin'>
    
    <h1>Cadastrar Produto</h1>

    <div className='row'>
      <div className='col-md-4'>
        <label for="nomeprod">Nome do Produto</label>
        <input type="text" id="nomeprod" className='form-control' onChange={defineNameProd}/>
      </div>

      <div className='col-md-4'>
        <label for="priceprod">Preço</label>
        <input type="number" id="priceprod" className='form-control' onChange={definePriceProd}/>
      </div>

      <div className='col-md-4'>
        <label for="priceprodpromo">Preço Promocional</label>
        <input type="number" id="priceprodpromo" className='form-control' onChange={definePricePromoProd}/>
      </div>

      <div className='col-md-4'>
        <label for="marcaprod">Marca do Produto</label>
        <input type="text" id="marcaprod" className='form-control' onChange={defineMarcaeProd}/>
      </div>

      <div className='col-md-4'>
        <label for="gtinprod">GTIN Produto</label>
        <input type="text" id="gtinprod" className='form-control' onChange={defineGtinProd}/>
      </div>

      <div className='col-md-4'>
        <label for="categprod">Categoria Produto</label>
        <select className="form-select" aria-label="Default select example" onChange={defineCategProd}>
          <option selected>Selecione a categoria</option>
          {categlist.map((item, index) => (
            <option key={index} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </div>


    <div className='row'>

      <div className='col-md-12'>
        <label for='descprod'>Descrição do Produto</label>
        <textarea className='form-control' onChange={defineTextProd}></textarea>
      </div>

      <div className='col-md-12'>
        <label for='descprod'>Mini Descrição</label>
        <textarea className='form-control' onChange={defineminidesc}></textarea>
      </div>

    </div>
    <p style={{marginTop: 10}}>Galeria de Imagens</p>
    <div className='row'>
      <div className='col-md-4'>
        <input style={{marginTop: 10}} type='file' className='inputimg form-control' accept="image/png, image/gif, image/jpeg" onChange={handleChange}/>
      </div>
      <div className='col-md-8 slidegalericad'>
          {imageList.map((item, index) => (
            <div className='container_image_galeriacad'>
              <button className=' btn btn-danger btn_remove_img_cad' onClick={() => deleteImg(index)}>Remover</button>
              <img src={item}  className='img_cad_galeria' />
            </div>
          ))}
      </div>
    </div>

    <div className='row' style={{marginTop: 10}}>
      <div className='col-md-4'>
        <label for="prodchaves">Palavras Chaves</label>
        <input type="text" id="prodchaves" className='form-control' onChange={defineSeoProd}/>
      </div>

      <div className='col-md-4' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <input class="form-check-input" type="checkbox" value="" id="proddestaque" checked={prodDest} onChange={defineProdDest}/>
        <label class="form-check-label" for="proddestaque" style={{marginLeft: 10, fontSize: 16, fontWeight: 'bold'}}>
            Produto Destaque
        </label>
      </div>

      <div className='col-md-4' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <button className='btn btn-primary' onClick={saveProduct}>Salvar</button>
      </div>
    </div>


    <div className='modal_image_preview' style={{display: modalimgpreviewvis}}>
      <div className='modal_image_prevew_content'>
        <img src={selectedImg.url} width="60%"/>
        <section className='contentbtnmodalimgpreview'>
          <button className='btn btn-primary' style={{marginTop: 10}} onClick={() => addImg(selectedImg.image)}>Adicionar Imagem</button>
          <button className='btn btn-danger' style={{marginTop: 10}} onClick={closeModalImgPreview}>Fechar</button>
        </section>
        
      </div>
    </div>

  </div>;
};

export default AddProd;