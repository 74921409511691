import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.png';
import { collection, query, where, onSnapshot, doc, getDocs, orderBy, startAt, endAt } from "firebase/firestore";
import { db } from '../../../firebaseConfig';

const Header = ({listadecateg, config}) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [logomarca, setLogomarca] = useState(null);
  const [celular, setCel] = useState(null);
  const [fixo, setFixo] = useState(null);
  const [email, setEmail] = useState(null);
  const [configL, setConfigL] = useState(null);
  const [searchD, setSearchD] = useState('');
  const [searchDisplay, setSearchDisplay] = useState("none");
  const [searchResult, setSearchResult] = useState([]);


  function formatProductNameForURLMOB(productName) {
    // Remover acentuação
    const withoutAccents = productName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    // Remover caracteres especiais, exceto espaços e hifens
    const cleaned = withoutAccents.replace(/[^a-zA-Z0-9\s-]/g, '');
    // Substituir espaços por hífens
    const withHyphens = cleaned.replace(/\s+/g, '-');
    // Converter para minúsculas
    const urlFriendly = withHyphens.toLowerCase();
    return urlFriendly;
  }

  const search_din = async(val)=>{
    setSearchD(val);

    console.log(val);

    if(val.length > 4){
        console.log("ja da pra efetuar a busca");
        const q = query(
            collection(db, 'product'),
            orderBy('name'),
            startAt(searchD),
            endAt(searchD + '\uf8ff')
          );

        
        const querySnapshot = await getDocs(q);
        const usersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setSearchDisplay('block');
        var dom = [];

        if(usersList.length){
            for(var i=0; i < usersList.length; i++){
                dom.push(
                    <div className='container_prod_search'>
                        <div className='row'>
                            <div className='col-md-4 col-sm-6'>
                                <img src={usersList[i].fotos[1]} alt={usersList[i].name} width="80"/>
                            </div>

                            <div className='col-md-8 col-sm-6'>
                                <h2>{usersList[i].name}</h2>
                                <Link to={'/produto/'+usersList[i].id+'/'+formatProductNameForURLMOB(usersList[i].name)}>Detalhes</Link>
                            </div>
                        </div>
                    </div>
                );
            }
        }else{
            dom.push(
                <div className='container_prod_search'>
                    <h2>Nenhum Produto encontrado...</h2>
                </div>
            )
        }

        setSearchResult(dom);

        console.log(usersList[0]);
    }
  }

  const closeSearch = () =>{
    setSearchDisplay('none');
  }

  useEffect(() => {

    const con = onSnapshot(doc(db, "config", "ktx"), (doc) => {
        
        setLogomarca(doc.data().logo);
        setCel(doc.data().contatos.celular);
        setFixo(doc.data().contatos.telefone);
        setEmail(doc.data().contatos.email);
    });

  }, []);

  const hoverfaleconosco = () =>{
    const element = document.getElementById('btnfaleconhover');

    if(element.dataset.state == 0){
        // ativando elemento
        element.dataset.state = 1;
        element.style.display  = "block";
    }else{
        // escondendo
        element.dataset.state = 0;
        element.style.display = "none";
    }
  }

  return <header>

        <div className='line_2header'>
            <div className='container'>
                <div className='row'>

                    {windowWidth >= 600 ?(
                        <div className='row'>
                            <div className='col-md-2 condivheader'>
                                <Link to="/"><img src={logomarca} alt="ktx brasil" width="212" height="75"/></Link>
                            </div>
                            <div className='col-md-10 condivheader' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end'}}>
                                <div style={{width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                                    <input type="text" name="search" id="search_input" placeholder='Oque está procurando?' value={searchD} onChange={e => search_din(e.target.value)} style={{width: '80%'}}/>
                                    <button style={{backgroundColor: 'transparent', border: 'none', fontSize: 30, color: '#A6A6A6', marginLeft: 5}}><i class="bi bi-search"></i></button>
                                </div>
                                <div className='searchResult' style={{display: searchDisplay}}>
                                    <button className='closeSearch' onClick={() => closeSearch()}><i class="bi bi-x-circle"></i></button>
                                    {searchResult}
                                </div>
                            </div>
                        </div>
                    ):(
                        <nav className="navbar" style={{backgroundColor: '#FFD333'}}>

                            <div className='content_geral_header'>
                                <div className='content_1_header'>
                                    <Link to="/"><img src={logo} alt="ktx brasil" width="212" height="75"/></Link>
                                </div>

                                <div className='content_2_header'>
                                        <a className="btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{marginRight: 10, backgroundColor: '#fff'}}>
                                            <i className='material-icons'>menu</i>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li style={{textAlign: 'center'}}>
                                                <input type="text" name="search" id="search_input" placeholder='Oque está procurando?' value={searchD} onChange={e => search_din(e.target.value)}/>
                                                <div className='searchResult' style={{display: searchDisplay}}>
                                                    <button className='closeSearch' onClick={() => closeSearch()}><i class="bi bi-x-circle"></i></button>
                                                    {searchResult}
                                                </div>
                                            </li>
                                            <li><Link className="dropdown-item" to='/'>Inicio</Link></li>
                                            <li><Link className="dropdown-item" to='/empresa'>Quem Somos</Link></li>
                                            <li><Link className="dropdown-item" to=''>Produtos</Link></li>
                                            <li><Link className="dropdown-item" to='/contatos'>Fale Conosco</Link></li>
                                            <li><Link className="dropdown-item" to=''>Fazer um Orçamento</Link></li>
                                            <li><hr className="dropdown-divider"/></li>
                                            <li><b className='dropdown-item' style={{fontWeight: 'bold', fontSize: 22}}> Categorias </b></li>
                                            {listadecateg.map((item, index) => (
                                                <li><Link className="dropdown-item" style={{fontWeight: '500', fontSize: 18}} to={'/categoria/'+item.id+'/'+formatProductNameForURLMOB(item.name)}>{item.name}</Link></li>
                                            ))}
                                        </ul>
                                </div>
                            </div>

                            
                        </nav>
                    )}

                </div>
            </div>
            
        </div>

        <div className='line_3header'>
            <div className='container'>
                <div className='row_line3'>
                    <section>
                        <div className='condivheader3 condivheader3_1'>
                            <button className='btn_line3_categ'>Categorias de Produtos</button>
                        </div>
                        <div className='condivheader3 condivheader3_2'>
                            <ul className='listpageheader3'>
                                <li><Link to='/'>Inicio</Link></li>
                                <li><Link to='/empresa'>Quem Somos</Link></li>
                                <li><Link to='/garantia'>Garantia</Link></li>
                            </ul>
                        </div>
                    </section>
                    
                    <button className='btnfaleconosco'  onMouseEnter={hoverfaleconosco} onMouseLeave={hoverfaleconosco}>
                        <i class="bi bi-telephone-inbound"></i> Fale Conosco

                        <div className='btnfaleconhover' id="btnfaleconhover" data-state="0">
                            <div className='row confale'>
                                <div className='col-md-2'>
                                    <i class="bi bi-telephone-inbound"></i>
                                </div>
                                <div className='col-md-10'>
                                    <h3 style={{fontSize: 14}}>Compre Por Telefone</h3>
                                    <Link to="/" style={{fontSize: 16, color: 'black', fontWeight: 600}}>{fixo}</Link>
                                </div>
                            </div>
                            <div className='row confale'>
                                <div className='col-md-2'>
                                    <i class="bi bi-whatsapp" style={{color: '#26C943'}}></i>
                                </div>
                                <div className='col-md-10'>
                                    <h3 style={{fontSize: 14}}>Fale No Whatsapp</h3>
                                    <Link to="https://api.whatsapp.com/send/?phone=5511972071385&text&type=phone_number&app_absent=0" style={{fontSize: 16, color: 'black', fontWeight: 600}}>{celular}</Link>
                                </div>
                            </div>
                            <div className='row confale'>
                                <div className='col-md-2'>
                                    <i class="bi bi-whatsapp" style={{color: '#26C943'}}></i>
                                </div>
                                <div className='col-md-10'>
                                    <h3 style={{fontSize: 14}}>Carvalho Vendas</h3>
                                    <Link to="https://api.whatsapp.com/send/?phone=5511933202432&text&type=phone_number&app_absent=0" style={{fontSize: 16, color: 'black', fontWeight: 600}}>11  93320-2432</Link>
                                </div>
                            </div>
                            <div className='row confale'>
                                <div className='col-md-2'>
                                    <i class="bi bi-envelope-at-fill"></i>
                                </div>
                                <div className='col-md-10'>
                                    <h3 style={{fontSize: 14}}>Envie Um E-mail</h3>
                                    <Link to="/" style={{fontSize: 16, color: 'black', fontWeight: 600}}>{email}</Link>
                                </div>
                            </div>

                            <div className='row confale'>
                                <div className='col-md-12'>
                                    <h3 style={{fontSize: 14}}>Hórario De Atendimento</h3>
                                    <p>Segunda á Sexta Das 8:00 as 17h</p>
                                </div>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>

  </header>;
};

export default Header;