import React, { useEffect, useState, useRef } from 'react';
import Header from '../contents/header';
import Footer from '../contents/footer';
import { BrowserRouter as Router, Route, Routes, useParams, Link, useNavigate } from 'react-router-dom';
import { collection, query, where, onSnapshot, addDoc } from "firebase/firestore";
import { db, Timestamp } from '../../firebaseConfig';
import './style.css';
import ProductImageZoom from './productImgZoom';
import SEO from '../seo';

const Product = ({categorialista}) => {

  const navigate = useNavigate();

  let { param1 } = useParams();
  const [productInfo, setProductInfo] = useState([]);

  const [productName, setProductname] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [productminidesc, setproductminidesc] = useState("");
  const [caminhoDom, setCaminhoDom] = useState();
  const [productRelac, setProductrelac] = useState([]);
  const [productImg, setProductImg] = useState([]);

  const [slideDom, setSlideDom] = useState(null);

  const [modalOrVis, setModalOrVis] = useState("none");

  const [galeriadeprodutos, setGaleria] = useState(null);

  const [seodesc, setSeodesc] = useState(null);

  function formatProductNameForURL(productName) {
    // Remover acentuação
    const withoutAccents = productName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    // Remover caracteres especiais, exceto espaços e hifens
    const cleaned = withoutAccents.replace(/[^a-zA-Z0-9\s-]/g, '');
    // Substituir espaços por hífens
    const withHyphens = cleaned.replace(/\s+/g, '-');
    // Converter para minúsculas
    const urlFriendly = withHyphens.toLowerCase();
    return urlFriendly;
  }

  const redirectPage = (id,name) =>{
    navigate('/inicio');
  }

  useEffect(() => {

    // puxando dados do produto

    const q = query(collection(db, "product"), where("id", "==", param1));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const product = [];
      querySnapshot.forEach((doc) => {
        product.push(doc.data());
      });

      setProductInfo(product);

      document.title = product[0].name;
      setProductname(product[0].name);
      setProductDesc(product[0].desc);
      setproductminidesc(product[0].minidesc);

      if(product[0].minidesc){
        setSeodesc(product[0].minidesc);
      }else{
        setSeodesc(product[0].desc);
      }

      function defineSlidep(index){
        if(index == 0){
          return "active";
        }else{
          return "";
        }
      }

      function defineLoading(index){
        if(index == 0){
          return "eager";
        }else{
          return 'lazy';
        }
      }

      setSlideDom(
        product[0].fotos.map((item, index) => (
          <div className={'carousel-item '+defineSlidep(index)}>
              <img src={item} className="itemslide" alt="..." loading={defineLoading(index)}/>
          </div>
        ))
      );

      setProductImg(product[0].fotos);

      setCaminhoDom(
        <ol className="breadcrumb breadmobile">
            <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
            <li className="breadcrumb-item">
              <Link to="">{product[0].category}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="">{product[0].name}</Link>
            </li>
        </ol>
      );

      // puxando produtos similares

      const qproductsimilar = query(collection(db, "product"), where("category", "==", product[0].category));
      const unsubscribeprodsimilar = onSnapshot(qproductsimilar, (querySnapshot) => {
        const productsSimilar = [];
        querySnapshot.forEach((doc) => {
          productsSimilar.push(doc.data());
        });

        setProductrelac(productsSimilar);

      });

    });

    
  }, [param1]);


  const openModalOr = () =>{
    setModalOrVis('flex');
  }

  const closeModalOr = () => {
    setModalOrVis('none');
  }

  // var cliente or

  const [nameClient, setnmClient] = useState("");
  const [emailClient, setemClient] = useState("");
  const [telClient, setteClient] = useState("");
  const [msgClient, setMsgClient] = useState("");

  // refs

  const inputName = useRef(null);
  const inputEmail = useRef(null);
  const inputTel = useRef(null);
  const inputMsg = useRef(null);

  const setNameClient = (e) => {
    setnmClient(e.target.value);
  }

  const setEmailClient = (e) => {
    setemClient(e.target.value);
  }

  const setTelClient = (e) => {
    setteClient(e.target.value);
  }

  const setMsgCliente = (e) => {
    setMsgClient(e.target.value);
  }

  const sendOr = async() => {
    if(nameClient == ""){
      alert("Preencha o campo NOME");
      inputName.current.focus();
    }else if(emailClient == ""){
      alert("Preencha o campo EMAIL");
      inputEmail.current.focus();
    }else if(telClient == ""){
      alert("Preencha o campo TELEFONE");
      inputTel.current.focus();
    }else{

      // registra o pedido do orçamento
      const docRef = await addDoc(collection(db, "orcamentos"), {
        nome: nameClient,
        email: emailClient,
        tel: telClient,
        msg: msgClient,
        produto: productName,
        data: Timestamp.now()
      });

      // enviando e-mail para ktx

      try {
        const body = {
          email: emailClient,
          nome: nameClient,
          tel: telClient,
          text: msgClient,
          productname: productName
        }
        const response = await fetch('https://ktxbrasil.com.br/php/send_email_empresa.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
        const data = await response.json();
        if (response.status == 200) {
          console.log('Email enviado com sucesso!');
        } else {
          console.log('Erro ao enviar o email: ' + data.message);
        }
      } catch (error) {
        console.log('Erro ao enviar o email: ' + error.message);
      }


      alert("Pedido de Orçamento enviado com sucesso. Entraremos em contato em breve!");
      setModalOrVis('none');
    }
  }

  const comprarZap = () =>{

  }

  return <div>
   <Header listadecateg={categorialista}/>
   <SEO
        title={productName+' - KTX BRASIL'}
        description={seodesc}
        image={productImg[0]}
        url="https://ktxbrasil.com.br"
    />
    <div className='container'>
      <nav aria-label="breadcrumb" style={{marginTop:10}}>   
          {caminhoDom}
      </nav>

      <div className='row'>
        <div className='col-md-6 col-sm-12'>
              <div className="slide_product" >
                <ProductImageZoom src={productImg} product={productName} />
              </div>
        </div>
        <div className='col-md-6 col-sm-12 infoproduct'>
          <h1 className='title_prod'>{productName}</h1>
          <br/>
          <b className='tagproductnew'>Produto novo!</b>
          <br/>
          <br/>
          <p>
            {productminidesc}
          </p>
          <hr/>
          <div className='row'>
            <div className='col-md-12 contentBtnOrMob'>
              <button className='btnor' onClick={openModalOr}>Faça um Orçamento!</button>
              <Link target='_blank' to={'https://api.whatsapp.com/send/?phone=5511972071385&text=Tenho Interesse no Produto '+formatProductNameForURL(productName)+'&type=phone_number&app_absent=0'} className='btncomprawhats'><i class="bi bi-whatsapp"></i>Comprar No WhatsApp</Link>
            </div>
          </div>
        </div>
      </div>


      <div className='container_extra_prod'>
        <section className='container_tab'>
          <button className='btntab btntabac'>Descrição</button>
          <button className='btntab'>Itens Incluos</button>
        </section>

        <section className='content_desc'>
          <p>
            {productDesc}
          </p>
        </section>
      </div>

      <div className='title_vitrine'>
                    <div className='row'>
                        <div className='col-md-2' style={{width: '13%'}}>
                            <h3>Outros Produtos</h3>
                        </div>
                        <div className='col-md-10'>
                            <hr/>
                        </div>
                    </div>
            </div>

        <div className='slideprodrec'>

          {productRelac.map((item, index) => {
            if(index < 4){
              return(
                <div className='card_prod' style={{width: 240, padding: '2vh'}}>
                    <div className='container_images'>
                        <img src={item.fotos[0]} alt={item.name}/>
                    </div>
                    <h1>{item.name}</h1>
                    <section className='container_avalia_categ'>
                          <i class="bi bi-star-fill"></i>
                          <i class="bi bi-star-fill"></i>
                          <i class="bi bi-star-fill"></i>
                          <i class="bi bi-star-fill"></i>
                          <i class="bi bi-star-fill"></i>
                        </section>
                    <p style={{textAlign: 'start', color: '#28a745', fontWeight: '500'}}>Disponivel</p>
                    <div style={{textAlign: 'center'}}>
                      <Link onClick={() => {redirectPage(item.id, item.name)}} className='btn_consult'>Mais Informações</Link>
                    </div>
                </div>
              )
            }
          })}
 
        </div>

    </div>


    <div className='containerModalOr' style={{display: modalOrVis}}>
      <div className='contentModalOr'>
          <h1>Solicitar Orçamento</h1>
          <h2>Produto: {productName}</h2>

          <p>Preencha os dados abaixo:</p>

          <br/>

          <div className='row'>
            <div className='col-md-4 col-sm-6'>
              <label>Nome</label>
              <input type='text' className='form-control' placeholder='ex: Maria josé' value={nameClient} onChange={setNameClient} ref={inputName}/>
            </div>
            <div className='col-md-4 col-sm-6'>
              <label>E-mail</label>
              <input type='email' className='form-control' placeholder='ex: seuemail@gmail.com' value={emailClient} onChange={setEmailClient} ref={inputEmail}/>
            </div>
            <div className='col-md-4 col-sm-6'>
              <label>Telefone de contato (fixo ou celular)</label>
              <input type='number' className='form-control' placeholder='ex: (dd) 00000-0000' value={telClient} onChange={setTelClient} ref={inputTel}/>
            </div>
          </div>

          <div className='row'>
            <label>Mensagem</label>
            <div className='col-md-12 col-sm-12'>
              <textarea className='form-control' placeholder='Deixe uma mensagem...' value={msgClient} onChange={setMsgCliente} ref={inputMsg}></textarea>
            </div>
          </div>

          <div className='row container_btn_modal_or_mobile' style={{marginTop: 10}}>
            <div className='col-md-6 col-sm-6' style={{textAlign: 'left'}}>
              <button className='btn btn-secondary btnor1' onClick={() => closeModalOr()}> Fechar </button>
            </div>
            <div className='col-md-6 col-sm-6' style={{textAlign: 'right'}}>
              <button className='btn btn-primary btnor2' onClick={() => sendOr()}> Enviar </button>
            </div>
          </div>
      </div>
    </div>


  <Footer listadecateg={categorialista}/>
  </div>;
};

export default Product;