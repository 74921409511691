import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../firebaseConfig';

const Login = () => {

    const [email, setEmail] = useState(null);
    const [senha, setSenha] = useState(null);

    const emailref = useRef(null);

    const defineMail = (event) => {
        setEmail(event.target.value);
    }

    const definePass = (event) => {
        setSenha(event.target.value);
    }

    const validaLogin = async () =>{
        signInWithEmailAndPassword(auth, email, senha)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log('logado');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert('usuário ou senha incorretos');
            });
    }

    const recuperarAcesso = async() =>{

        if(email){
            sendPasswordResetEmail(auth, email)
            .then(() => {
              alert("E-mail de recupeção enviado com sucesso!");
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
    
              console.log(errorCode);
              console.log(errorMessage);
            });
        }else{
            emailref.current.focus();
            alert("Preencha o campo e-mail");
        }

        
    }

  return <div className='container_p_login'>
    <form className='container_login'>
        <section style={{textAlign: 'center'}}>
            <img src={logo} alt="ktx brasil"/>
        </section>
        
        <div className='row'>
            <div className='col-md-12'>
                <label for="cpf">E-MAIL:</label>
                <input ref={emailref} className="form-control" type="email" id="cpf" aria-label="Preencha seu e-mail" onChange={defineMail}/>
            </div>
            <div className='col-md-12'>
                <label for="senha">SENHA:</label>
                <input className="form-control" type="password" id="senha" aria-label="Preencha sua senha" onChange={definePass}/>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-8' style={{paddingTop: 10}}>
                <button type="button" className='btn btn-secondary' onClick={recuperarAcesso}>Esqueci Minha Senha</button>
            </div>
            <div className='col-md-4' style={{textAlign: 'right', paddingTop:10}}>
                <button type="button" onClick={validaLogin} className="btn btn-primary">Entrar</button>
            </div>
        </div>
    </form>
  </div>;
};

export default Login;