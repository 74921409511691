import React, { useState, useRef, useEffect } from 'react';

const ProductImageZoom = ({ src, zoomLevel = 2, product }) => {
    const [stateBg, setStateBg] = useState('0% 0%');
    const [slideP, setSlideP] = useState(null);
    const [galeriadeprodutos, setGaleria] = useState(null);

    const handleMouseMove = e => {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100
        const y = (e.pageY - top) / height * 100
        setStateBg(`${x}% ${y}%`)
    }

    const updateImage = (index) => {
      setSlideP(src[index]);

      const slide = document.getElementById('slidevm_p');
      document.getElementById('minhaturaslide_'+slide.dataset.atual).classList.remove('imgativa');

      document.getElementById('minhaturaslide_'+index).classList.add('imgativa');

      document.getElementById('slidevm_p').dataset.atual = index;
    }

    useEffect(() => {
        setSlideP(src[0]);

        setGaleria(
            src.map((item, index) => {
              if(index == 0){
                return(
                  <img src={item} className="itemgaleria imgativa" alt="..." loading="lazy" id={'minhaturaslide_'+index} onClick={() => updateImage(index)}/>
                )
              }else{
                return(
                  <img src={item} className="itemgaleria" alt="..." loading="lazy" id={'minhaturaslide_'+index} onClick={() => updateImage(index)}/>
                  
                )
              }
            })
          );
    }, [src]);

    const slideControl = (action)=>{
        // pegando step atual do slide
        const slide = document.getElementById('slidevm_p');
    
        const imgs = src;
    
        // lidando de acordo com a ação
    
        if(action == "prev"){
          if(slide.dataset.atual == 0){
    
            // removendo active da minhatura
    
            document.getElementById('minhaturaslide_'+slide.dataset.atual).classList.remove('imgativa');
    
            var step = parseInt(src.length) - parseInt(1);
            slide.dataset.atual = step;
    
            // mostrando nova img
    
            setSlideP(src[step]);
    
            // adicionando active na nova minhatura
    
            document.getElementById('minhaturaslide_'+step).classList.add('imgativa');
          }else{    
            // removendo active da minhatura
    
            document.getElementById('minhaturaslide_'+slide.dataset.atual).classList.remove('imgativa');
    
            var step = parseInt(slide.dataset.atual) - 1;
    
            slide.dataset.atual = step;
    
            // mostrando nova img
    
            setSlideP(src[step]);
    
            // adicionando active na nova minhatura
    
            document.getElementById('minhaturaslide_'+step).classList.add('imgativa');
          }
        }else{

        var max = parseInt(imgs.length) - parseInt(1);
    
          if(slide.dataset.atual == max){
            var step = 0;
    
            // removendo active da minhatura
    
            document.getElementById('minhaturaslide_'+slide.dataset.atual).classList.remove('imgativa');
    
            slide.dataset.atual = step;
    
            // mostrando nova img
    
            setSlideP(src[step]);

            
    
            // adicionando active na nova minhatura
    
            document.getElementById('minhaturaslide_'+step).classList.add('imgativa');
          }else{
            var step = parseInt(slide.dataset.atual)+1;
    
            // removendo active da minhatura
    
            document.getElementById('minhaturaslide_'+slide.dataset.atual).classList.remove('imgativa');
    
            slide.dataset.atual = step;
    
            // mostrando nova img
    
            setSlideP(src[step]);
    
            // adicionando active na nova minhatura
    
            document.getElementById('minhaturaslide_'+step).classList.add('imgativa');
          }
    
        }
    
      }


  return (
    <div className="slider-container">
            <div className='container_btn_slide'>
                  <button className='btn-control-slide btn-prev-slide' onClick={() => {slideControl('prev')}}><i class="bi bi-arrow-left"></i></button>
                  <button className='btn-control-slide btn-prox-slide' onClick={() => {slideControl('prox')}}><i class="bi bi-arrow-right"></i></button>
              </div>
            <div className='slide_vm' data-atual="0" id="slidevm_p">
                <figure onMouseMove={handleMouseMove} style={{backgroundPosition: stateBg, backgroundImage: 'URL('+slideP+')'}}>
                    <img src={slideP} alt={product} id="image"/>
                </figure>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <section className='galeria_de_produtos'>
                        {galeriadeprodutos}
                    </section>
                </div>
            </div>
        </div>
  );
};

export default ProductImageZoom;