import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, query, where, onSnapshot, doc, deleteDoc} from "firebase/firestore";
import { db, auth, storage } from '../../../../firebaseConfig';
import { onAuthStateChanged, signOut, getAuth } from 'firebase/auth';
import { getStorage, ref, deleteObject } from "firebase/storage";

import CategoriaModal from './modals/categoria';
import AddProd from './modals/addprod';
import EditProd from './modals/editprod';

const ProdutosAdmin = () => {

    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    const [numProd, setNumProd] = useState(0);
    const [domModal, setDomModal] = useState(null);
    const [visModal, setVisModal] = useState('none');
    const [listProducts, setListProducts] = useState([]);

    const openModal = (type, idprod) => {
        setVisModal('flex');
        if(type == "categoria"){
            setDomModal(<CategoriaModal/>);
        }else if(type == "addprod"){
            setDomModal(<AddProd/>);
        }else if(type == "gerenciarprod"){
            setDomModal(<EditProd idproduct={idprod}/>);
        }   
    }

    const closeModal = () => {
        setVisModal('none');
        setDomModal(null);
    }

    useEffect(() => {

        document.title = "KTX BRASIL ADM - Gerenciar Produtos";

        // verificando se user esta logado

        const checklog = onAuthStateChanged(auth, (user) => {
              if (user) {
                setUser(user);
                console.log('logado');
                const usercorrent = auth.currentUser;
              } else {
                setUser(null);
                navigate("/admin");
              }
        });
        
        // pegando produtos cadastrados

        const q = query(collection(db, "product"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const prods = [];
        querySnapshot.forEach((doc) => {
            prods.push(doc.data());
        });
        setListProducts(prods);
        setNumProd(prods.length);
        });
  
    }, []);

    const sair = () =>{
        signOut(auth).then(() => {
          console.log("Deslogado");
        }).catch((error) => {
          console.log("error ao deslogar");
        });
    }

    const deleteProduct = async(idpr) => {

        if(window.confirm("Deseja realmente excluir esse produto?")){
            const qdelete = query(collection(db, "product"), where("id", "==", idpr));
            const unsubscribedel = onSnapshot(qdelete, (querySnapshot) => {
                var proddelid = null;
                const proddelinfo = [];
                querySnapshot.forEach((doc) => {
                    proddelid = doc.id;
                    proddelinfo.push(doc.data());
                });


                if(proddelid){
                    // excluindo produto selecionado e imagens

                    // removendo imagens

                    const imagemremove = proddelinfo[0].fotos;

                    for(var i=0; i < imagemremove.length; i++){

                        const fileUrl = imagemremove[i];

                        // Extraia o caminho do arquivo da URL
                        const filePath = fileUrl.split("/o/")[1].split("?")[0].replace(/%2F/g, "/");

                        // Crie uma referência para o arquivo
                        const fileRef = ref(storage, filePath);

                        // Delete o arquivo
                        deleteObject(fileRef);

                    }

                    deleteDoc(doc(db, "product", proddelid));
                }

                

            });


            alert("Produto Excluido com sucesso!");
        }
    }


  return <div className='container_painel_admin'>
    <nav className="navbar" style={{backgroundColor: '#FFD333'}}>
        <div className="container-fluid">
            <a className="navbar-brand">KTX Brasil</a>
            <button className='btn btn-primary' onClick={sair}>Sair</button>
        </div>
    </nav>

    <div className='container container_padmin' style={{marginTop: 20}}>
        <nav aria-label="breadcrumb" style={{marginTop: 10}}>
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/admin">Inicio</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Gerenciar Produtos</li>
            </ol>
        </nav>
        <div className='header_prod'>
            <div className='row'>
                <div className='col-md-6'>
                    <button className='btn btn-primary' onClick={() => openModal('addprod')}>Cadastrar Produto ({numProd})</button>
                </div>
                <div className='col-md-6' style={{textAlign: 'right'}}>
                    <button className='btn btn-warning' onClick={() => openModal('categoria')}>Gerenciar Categorias</button>
                </div>
            </div>
        </div>

        <div className='row'>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">id</th>
                        <th scope="col">Foto</th>
                        <th scope="col">nome</th>
                        <th scope="col">preço</th>
                        <th scope="col">marca</th>
                        <th scope="col">Gerenciar</th>
                        <th scope="col">Excluir</th>
                    </tr>
                </thead>

                <tbody>
                {listProducts.map((item, index) => (
                    <tr>
                        <td>{item.id}</td>
                        <td><img src={item.fotos[0]} width="60"/></td>
                        <td>{item.name}</td>
                        <td>{item.price}</td>
                        <td>{item.marca}</td>
                        <td><button className='btn btn-primary' onClick={() => openModal('gerenciarprod', item.id)}>Gerenciar</button></td>
                        <td><button className='btn btn-danger' onClick={() => deleteProduct(item.id)}>Excluir</button></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    </div>


    <div className='modalvm' style={{display: visModal}}>
        <div className='modalvmcontent'>
            <button className='btn btn-danger' style={{float: 'right'}} onClick={closeModal}>X Fechar</button>
            {domModal}
        </div>
    </div>

  </div>;
};

export default ProdutosAdmin;