import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useLocation } from 'react-router-dom';
import Header from '../../contents/header';
import Footer from '../../contents/footer';



const QuemSomos = ({categorialista}) => {
    document.title = "KTX BRASIL - Quem Somos";
    return(
    <div>
        <Header listadecateg={categorialista}/>

        <div className='container_quem_somos'>
            <div className='container content_quem_somos'>

                <section>
                    <h1>A EMPRESA</h1>
                    <p>Fundada em 2020, a <b>KTX BRASIL</b> oferece máquinas, equipamentos e ferramentas para diversos setores.</p>
                    <br></br>
                    <p>A <b>KTX BRASIL</b> quer fazer parte da sua vida através dos produtos com o melhor benefício x custo do mercado.</p>
                    <br></br>
                    <b style={{textTransform: 'uppercase', width: '100%', textAlign: 'center'}}>Visão</b>
                    <p>Ser a primeira escolha dos clientes que desejam adquirir ferramentas, máquinas e equipamentos.</p>
                    <br></br>
                    <b style={{textTransform: 'uppercase', width: '100%', textAlign: 'center'}}>MISSÃO</b>
                    <p>Gerar valor aos clientes e partes interessadas.</p>
                    <br></br>
                    <b style={{textTransform: 'uppercase', width: '100%', textAlign: 'center'}}>Valores</b>
                    <p>Integridade; Ética; Lealdade; Transparência; Profissionalismo.</p>
                    <br></br>
                    <b style={{textTransform: 'uppercase', width: '100%', textAlign: 'center'}}>O NOSSO COMPROMISSO</b>
                    <p>Proporcionar resultados, o que consequentemente leva à satisfação de nossos clientes. É a clareza de nosso bem-estar em servir, é o comprometimento de todos na busca da perfeição.</p>
                    <br></br>
                    <b style={{textTransform: 'uppercase', width: '100%', textAlign: 'center'}}>A RESPONSABILIDADE SOCIAL</b>
                    <p>Vemos a empresa como parte da comunidade, de forma harmoniosa, entre pessoas e meio ambiente, com respeito e também equilíbrio em todos os processos.</p>
                    <br></br>
                    <b style={{textTransform: 'uppercase', width: '100%', textAlign: 'center'}}>OS VALORES ÉTICOS E MORAIS</b>
                    <p>Definimos como nosso mais valioso patrimônio, pois nos norteia sobre quem somos, o que fazemos, onde pretendemos chegar e como chegar lá..</p>
                </section>


            </div>
        </div>
        

        <Footer listadecateg={categorialista}/>
    </div>
)};

export default QuemSomos;