import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';
import { auth } from './firebaseConfig';
import { BrowserRouter as Router, useRoutes, Link } from 'react-router-dom';
import Home from './componentes/home';
import Product from './componentes/product/index';
import Contact from './componentes/contact/index';
import Category from './componentes/category/index';
import Dashboard from './componentes/admin/index';
import Registro from './componentes/admin/componentes/registro';
import ProdutosAdmin from './componentes/admin/componentes/produtos';
import SlideGerencia from './componentes/admin/slide';
import Orcamento from './componentes/admin/orcamento';
import Garantia from './componentes/outras/garantia';
import Meiospag from './componentes/outras/meiospag';
import QuemSomos from './componentes/outras/quemsomos';
import Contatos from './componentes/outras/contato';
import Config from './componentes/admin/config';
import ScrollToTop from './componentes/ScrollToTop';

import { collection, query, where, onSnapshot, doc } from "firebase/firestore";
import { db } from './firebaseConfig';

const AppRoutes = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [categlist, setCategList] = useState([]);
  const [categlistteste, setCateglistteste] = useState([]);
  const [configLoja, setConfigLoja] = useState(null);


        const q = query(collection(db, "category"), where("type", "==", 1));
         const unsubscribe = onSnapshot(q, (querySnapshot) => {
         const categs = [];
         const arrPrincipal = [];
         querySnapshot.forEach((doc) => {

            var arrteste = doc.data();
             categs.push(doc.data());

             // puxando subs
             const qcategsubs = query(collection(db, "category"), where("type", "==", 2), where("categmae", "==", doc.data().id));
             const unsubscribecategsubs = onSnapshot(qcategsubs, (querySnapshot) => {
               const categsubs = [];
               querySnapshot.forEach((docc) => {
                  categsubs.push(docc.data());
               });

               arrteste['subs'] = categsubs;
               arrteste['numsubs'] = categsubs.length;
               
             });
             
             arrPrincipal.push(arrteste);

         });
 
         setCategList(arrPrincipal);
 
         });

        const con = onSnapshot(doc(db, "config", "ktx"), (doc) => {
          setConfigLoja(doc.data());  
        });

  useEffect(() => {

    /*if(windowWidth <= 600){
        // puxando as categorias mobile

        const q = query(collection(db, "category"), where("type", "==", 1));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const categs = [];
        querySnapshot.forEach((doc) => {
            categs.push(doc.data());
        });

        setCategList(categs);

        });
    }else{
         // puxando as categorias desktop

         const q = query(collection(db, "category"), where("type", "==", 1));
         const unsubscribe = onSnapshot(q, (querySnapshot) => {
         const categs = [];
         const arrPrincipal = [];
         querySnapshot.forEach((doc) => {

            var arrteste = doc.data();
            arrteste['olaa'] = "olaaa";

             categs.push(doc.data());

             // puxando subs
             const qcategsubs = query(collection(db, "category"), where("type", "==", 2), where("categmae", "==", doc.data().id));
             const unsubscribecategsubs = onSnapshot(qcategsubs, (querySnapshot) => {
               const categsubs = [];
               querySnapshot.forEach((docc) => {
                  categsubs.push(docc.data());
               });

               arrteste['subs'] = categsubs;
               arrteste['numsubs'] = categsubs.length;
               
             });
             
             arrPrincipal.push(arrteste);

         });
 
         setCategList(arrPrincipal);
 
         });
    }    */

  }, []);

  let routes = useRoutes([
    { path: "/", element: <Home categorialista={categlist} config={configLoja}/> },
    { path: "/produto/:param1/:param2", element: <Product categorialista={categlist} config={configLoja}/> },
    { path: "/contato", element: <Contact categorialista={categlist}/> },
    { path: "/admin", element: <Dashboard /> },
    { path: "/registro", element: <Registro /> },
    { path: "/gerenciarprodutos", element: <ProdutosAdmin /> },
    { path: "/slidegerencia", element: <SlideGerencia /> },
    { path: "/categoria/:param1/:param2", element: <Category categorialista={categlist} config={configLoja}/> },
    { path: "/orcamentos", element: <Orcamento /> },
    { path: "/garantia", element: <Garantia categorialista={categlist} config={configLoja}/> },
    { path: "/pagamento", element: <Meiospag categorialista={categlist} config={configLoja}/> },
    { path: "/empresa", element: <QuemSomos categorialista={categlist} config={configLoja}/> },
    { path: "/contatos", element: <Contatos categorialista={categlist} config={configLoja}/> },
    { path: "/config", element: <Config/> }
  ]);
  return routes;
};

function App() {
  return (
    <div className="App">
        <Router>
          <ScrollToTop />
          <AppRoutes />
        </Router>
    </div>
  );
}

export default App;
