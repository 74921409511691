import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png';
import { auth, db, storage } from '../../../firebaseConfig';
import { onAuthStateChanged, signOut, getAuth } from 'firebase/auth';

const Admin = () => {

    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "KTX BRASIL ADM - Configurações";
      }, []);


    const sair = () =>{
        signOut(auth).then(() => {
          console.log("Deslogado");
        }).catch((error) => {
          console.log("error ao deslogar");
        });
    }


  return <div className='container_painel_admin'>
    <nav className="navbar" style={{backgroundColor: '#FFD333'}}>
        <div className="container-fluid">
            <a className="navbar-brand">KTX Brasil</a>
            <button className='btn btn-primary' onClick={sair}>Sair</button>
        </div>
    </nav>

    <div className='container container_padmin' style={{marginTop: 20}}>
        <div className='row'>
            <div className='col-md-4 col-sm-12'>
                <div className="card" style={{width: '95%', height: 170}}>
                    <div className="card-body">
                        <h5 className="card-title">Gerenciar Produtos</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Adicione, Edite seus produtos...</h6>
                        <p className="card-text">Gerencie tambem suas categorias.</p>
                        <Link to="../gerenciarprodutos" className="card-link">Acessar</Link>
                    </div>
                </div>
            </div>
            <div className='col-md-4 col-sm-12'>
                <div className="card" style={{width: '95%', height: 170}}>
                    <div className="card-body">
                        <h5 className="card-title">Gerenciar Slides</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Edite seus slides e banners...</h6>
                        <p className="card-text">Atualize os banners do site...</p>
                        <Link to="../slidegerencia" className="card-link">Acessar</Link>
                    </div>
                </div>
            </div>
            <div className='col-md-4 col-sm-12'>
                <div className="card" style={{width: '95%', height: 170}}>
                    <div className="card-body">
                        <h5 className="card-title">Orçamentos Recebidos</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Acompanhe as solicitações de orçamento realizadas pelo seus clientes.</h6>
                        <p className="card-text">Responda via e-mail ou whatsapp</p>
                        <Link to="../orcamentos" className="card-link">Acessar</Link>
                    </div>
                </div>
            </div>
        </div>

        <div className='row' style={{marginTop: 20}}>
            <div className='col-md-4 col-sm-12'>
                <div className="card" style={{width: '95%', height: 170}}>
                    <div className="card-body">
                        <h5 className="card-title">Visitas</h5>
                        <h6 className="card-subtitle mb-2 text-muted" style={{textAlign: 'center', fontSize: 30}}>0</h6>
                    </div>
                </div>
            </div>
                <div className='col-md-4 col-sm-12'>
                    <div className="card" style={{width: '95%', height: 170}}>
                        <div className="card-body">
                            <h5 className="card-title">Configurações Geraiss</h5>
                            <h6 className="card-subtitle mb-2 text-muted">Edite as Informações principais de sua loja</h6>
                            <p className="card-text">Mude logomarca, Informações de contato...</p>
                            <Link to="../config" className="card-link">Acessar</Link>
                        </div>
                    </div>
                </div>
        </div>
    </div>
  </div>;
};

export default Admin;