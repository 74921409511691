// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAOUepFptU32xb3lrzoExPThH3AJXeU7MA",
    authDomain: "ktxbrasil-8286d.firebaseapp.com",
    projectId: "ktxbrasil-8286d",
    storageBucket: "ktxbrasil-8286d.appspot.com",
    messagingSenderId: "327413125107",
    appId: "1:327413125107:web:11cd92d2222acaf3e1cc86",
    measurementId: "G-1NBT6ZZF3N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app, "gs://ktxbrasil-8286d.appspot.com");

export { auth, db, storage, Timestamp };