import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useLocation } from 'react-router-dom';
import Header from '../../contents/header';
import Footer from '../../contents/footer';



const Contatos = ({categorialista}) => {
    document.title = "KTX BRASIL - Contatos";
    return(
    <div>
        <Header listadecateg={categorialista}/>

        <div className='container'>
            <nav aria-label="breadcrumb" style={{marginTop: 10}}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to='/'>Inicio</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Contatos</li>
                </ol>
            </nav>

            <h1>Contatos</h1>
            
            <div className='content_garantia'>
                <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                        <h1>Localização</h1>
                    </div>
                </div>
            </div>

        </div>

        <Footer listadecateg={categorialista}/>
    </div>
)};

export default Contatos;