import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, onSnapshot, addDoc, doc, deleteDoc } from "firebase/firestore";
import { db, storage, auth } from '../../../firebaseConfig';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage";
import { onAuthStateChanged, signOut, getAuth } from 'firebase/auth';

const SlideGerencia = () => {

    const [modaladdslidevis, setmodaladdslide] = useState('none');
    const [slides, setSlides] = useState([]);
    const [selectedImg, setSelectedImg] = useState([]);
    const [linkSlide, setLinkSlide] = useState(null);
    const [nameSlide, setNameSlide] = useState(null);
    const [tpslide, setTpSlide] = useState("desktop");

    // mobile

    const [slidesMob, setSlideMob] = useState([]);


    useEffect(() => {

      document.title = "KTX BRASIL ADM - Gerenciar Slides";

      // puxando slides desktop

      const q = query(collection(db, "slide"), where("device", "==", "desktop"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const slides = [];
        querySnapshot.forEach((doc) => {
            slides.push(doc.data());
        });
        setSlides(slides);
      });

      // puxando slides mobiles

      const qmobile = query(collection(db, "slide"), where("device", "==", "mobile"));
      const unsubscribeMob = onSnapshot(qmobile, (querySnapshot) => {
        const slidesMob = [];
        querySnapshot.forEach((doc) => {
            slidesMob.push(doc.data());
        });
        setSlideMob(slidesMob);
      });

    }, []);


    const setLink = (e) =>{
      setLinkSlide(e.target.value);
    }

    const setName = (e) => {
      setNameSlide(e.target.value);
    }


    const openModal = () =>{
      setmodaladdslide('flex');
    }

    const closeModal = () => {
      setmodaladdslide('none');
    }

    const setSlideTp = (e) => {
      setTpSlide(e.target.value);
    }

    const handleChange = e => {
      if (e.target.files[0]) {
  
        const arr = [{image: e.target.files[0], url: URL.createObjectURL(e.target.files[0])}];
        setSelectedImg(arr[0]);
        /*const previewUrl = URL.createObjectURL(e.target.files[0]);
        setImage(previewUrl);*/
        //setmodalimgpreviewvis("flex");
      }
    };

    function gerarStringUnica() {
      // Obtém o timestamp atual em milissegundos
      const timestamp = Date.now().toString(36); // Converte o timestamp para uma string em base 36 (0-9a-z)
      
      // Gera uma string aleatória de letras
      const letras = 'abcdefghijklmnopqrstuvwxyz';
      let stringAleatoria = '';
      for (let i = 0; i < 5; i++) { // Gera uma string de 5 letras aleatórias
          stringAleatoria += letras[Math.floor(Math.random() * letras.length)];
      }
      
      // Combina o timestamp com a string aleatória
      const stringUnica = timestamp + stringAleatoria;
      
      return stringUnica;
  }

  const uploadSlide = async(uri) => {
     // Criar um nome de arquivo único
     const filename = uri.substring(uri.lastIndexOf('/') + 1);
     const response = await fetch(uri);
     const blob = await response.blob();

     // Referência ao arquivo no Firebase Storage
     const storageRef = ref(storage, filename);

     // Upload do arquivo para o Firebase Storage
     await uploadBytes(storageRef, blob);

     // Obter a URL de download
     const url = await getDownloadURL(storageRef);

     const docRef = await addDoc(collection(db, "slide"), {
      id: gerarStringUnica(),
      img: url,
      name: nameSlide,
      url: linkSlide,
      device: tpslide
    });

    console.log('slide enviado');

    setmodaladdslide("none");

    alert("Slide Enviado com sucesso!");
  }


  const deleteSlide = async(slide) => {
    const qslidedel = query(collection(db, "slide"), where("id", "==", slide));
    const unsubscribe = onSnapshot(qslidedel, (querySnapshot) => {
      var slideID = null;
      const slideInfo = [];
      querySnapshot.forEach((doc) => {
          slideID = doc.id;
          slideInfo.push(doc.data());
      });

      // deletar slide e arquivo

      if(slideID){

        if(window.confirm("Deseja Realmente Excluir esse slide?")){

          // excluindo imagem 
          const fileUrl = slideInfo[0].img;

          // Extraia o caminho do arquivo da URL
          const filePath = fileUrl.split("/o/")[1].split("?")[0].replace(/%2F/g, "/");

          // Crie uma referência para o arquivo
          const fileRef = ref(storage, filePath);

          // Delete o arquivo
          deleteObject(fileRef);

          // deletando slide 

          deleteDoc(doc(db, "slide", slideID));

          alert("Slide Removido com sucesso!");
          
        }

      }

    });
  }

  const sair = () =>{
    signOut(auth).then(() => {
      console.log("Deslogado");
    }).catch((error) => {
      console.log("error ao deslogar");
    });
  }



  return <div className='container_painel_admin'>
    <nav className="navbar" style={{backgroundColor: '#FFD333'}}>
        <div className="container-fluid">
            <a className="navbar-brand">KTX Brasil</a>
            <button className='btn btn-primary' onClick={sair}>Sair</button>
        </div>
    </nav>

    <div className='container'>

      <nav aria-label="breadcrumb" style={{marginTop: 10}}>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/admin">Inicio</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Gerenciar Slides</li>
        </ol>
      </nav>


      <div className='container_geral_slide'>
        
        <div className='row'>
          <div className='col-md-4 col-sm-12'>
            <button className='btn btn-primary' onClick={openModal}>Adicionar Slide</button>
          </div>
        </div>

        <div className='row' style={{marginTop: '2vh'}}>
          <h4>Desktop ({slides.length})</h4>
        </div>

        <div className='row'>
          {slides.map((item, index) => (
            <div className='col-md-4'>

                <section className="card">
                  <div className='row' style={{marginTop: 10}}>
                      <div className='col-md-12' style={{textAlign: 'center'}}>
                       <img src={item.img} width="100%"/>
                      </div>
                    </div>
                    <div className='row' style={{marginTop: 15, paddingBottom: 10}}>
                      <div className='col-md-6'>
                        <b style={{marginLeft: 10, textTransform: 'capitalize', padding: '1vh', backgroundColor: '#FFD333', borderRadius: 5}}>{item.device}</b>
                      </div>
                      <div className='col-md-6' style={{textAlign: 'right'}}>
                        <button className='btn btn-danger' onClick={() => deleteSlide(item.id)} style={{marginRight: 10, marginTop: -10}}>Remover</button>
                      </div>
                    </div>
                </section>

                
            </div>
          ))}
        </div>


        <div className='row' style={{marginTop: 15}}>
          <h4>Mobile ({slidesMob.length})</h4>
        </div>

        <div className='row'>
          {slidesMob.map((item, index) => (
            <div className='col-md-4'>

                <section className="card">
                  <div className='row' style={{marginTop: 10}}>
                      <div className='col-md-12' style={{textAlign: 'center'}}>
                       <img src={item.img} width="100%"/>
                      </div>
                    </div>
                    <div className='row' style={{marginTop: 15, paddingBottom: 10}}>
                      <div className='col-md-6'>
                        <b style={{marginLeft: 10, textTransform: 'capitalize', padding: '1vh', backgroundColor: '#FFD333', borderRadius: 5}}>{item.device}</b>
                      </div>
                      <div className='col-md-6' style={{textAlign: 'right'}}>
                        <button className='btn btn-danger' onClick={() => deleteSlide(item.id)} style={{marginRight: 10, marginTop: -10}}>Remover</button>
                      </div>
                    </div>
                </section>

                
            </div>
          ))}
        </div>

      </div>
    </div>

    <div className='modal_add_slide' style={{display: modaladdslidevis}}>
      <div className='content_add_slide'>

        <div className='row'>
          <label>Nome do Slide</label>
          <input type='text' placeholder='nome do banner' className='form-control' onChange={setName}/>
        </div>

        <div className='row' style={{marginTop: 10}}>
          <label>Link do Slide</label>
          <input type='text' placeholder='link do banner' className='form-control' onChange={setLink}/>
        </div>

        <div className='row' style={{marginTop: 10}}>
          <label>Tipo de Slide</label>
          <select className='form-control' onChange={setSlideTp}>
            <option value="desktop">Desktop</option>
            <option value="mobile">Mobile</option>
          </select>
        </div>

        <div className='row'>
          <input style={{marginTop: 10}} type='file' className='inputimg form-control' accept="image/png, image/gif, image/jpeg" onChange={handleChange}/>
        </div>

        <div className='row'>
          <section style={{width: '100%', textAlign: 'center'}}>
            <img src={selectedImg.url} width="250"/>
          </section>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <button className='btn btn-danger' onClick={closeModal}>Fechar</button>
          </div>

          <div className='col-md-6' style={{textAlign: 'right'}}>
            <button className='btn btn-primary' onClick={() => uploadSlide(selectedImg.url)}>Enviar Imagem</button>
          </div>
        </div>

      </div>
    </div>

  </div>;
};

export default SlideGerencia;