import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebaseConfig';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../firebaseConfig';

const Registro = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState(null);
    const [senha, setSenha] = useState(null);
    const [senharep, setSenharep] = useState(null);
    const [nome, setNome] = useState(null);

    const defineMail = (event) => {
        setEmail(event.target.value);
    }

    const definePass = (event) => {
        setSenha(event.target.value);
    }

    const definePassRep = (event) => {
        setSenharep(event.target.value);
    }

    const defineName = (event) => {
        setNome(event.target.value);
    }

    const cadastrarUser = async () =>{
        createUserWithEmailAndPassword(auth, email, senha)
            .then((userCredential) => {
                // Signed up 
                const user = userCredential.user;

                // registrando resto das info

                const docRef = addDoc(collection(db, "user"), {
                    name: nome,
                    email: email,
                 });

                navigate('/admin');

                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log(errorCode);
                // ..
            });
    }

  return <div className='container_p_login'>
    <form className='container_login'>
        <section style={{textAlign: 'center'}}>
            <img src={logo} alt="ktx brasil"/>
        </section>
        
        <div className='row'>
            <div className='col-md-12'>
                <label for="email">Nome Completo:</label>
                <input className="form-control" type="email" id="email" aria-label="Preencha seu e-mail" onChange={defineName}/>
            </div>
            <div className='col-md-12'>
                <label for="email">E-MAIL:</label>
                <input className="form-control" type="email" id="email" aria-label="Preencha seu e-mail" onChange={defineMail}/>
            </div>
            <div className='col-md-12'>
                <label for="senha">SENHA:</label>
                <input className="form-control" type="password" id="senha" aria-label="Preencha sua senha" onChange={definePass}/>
            </div>
            <div className='col-md-12'>
                <label for="senharep">Confirme A Senha:</label>
                <input className="form-control" type="password" id="senharep" aria-label="Preencha sua senha" onChange={definePassRep}/>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-8'>
                <Link to='/admin'>Já possui conta faça o login</Link>
            </div>
            <div className='col-md-4' style={{textAlign: 'right', paddingTop:10}}>
                <button type="button" onClick={cadastrarUser} className="btn btn-primary">Registrar-Se</button>
            </div>
        </div>
    </form>
  </div>;
};

export default Registro;