import React, { useEffect, useState, useRef } from 'react';
import Header from '../contents/header';
import Footer from '../contents/footer';
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from '../../firebaseConfig';
import slide1 from '../images/slide-1.jpg';
import slide2 from '../images/slide-2.jpg';
import slide3 from '../images/slide-3.jpg';
import banner from '../images/banner-1.jpg';
import { BrowserRouter as Router, Route, Switch, Link, useLocation } from 'react-router-dom';
import SEO from '../seo';



const Home = ({categorialista, config}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [prodDestaquelist, setProdDestaqueList] = useState([]);
    const [slides, setSlides] = useState([]);
    const [categhomedom, setCategHomeDom] = useState([]);
    const [prodPecasList, setPecasList] = useState([]);
    const [imglink, setImgLink] = useState(null);
    const [prodpPe, setProdP] = useState();

    const boxslideproduct1 = useRef(null);

    useEffect(() => {

        //setImgLink(config.logo);

        // puxando peças de reposição

        const qprodPecas = query(collection(db, "product"), where("category", "==", "Peças de Reposição"));
        const unsubscribepecas = onSnapshot(qprodPecas, (querySnapshot) => {
        const prodspecas = [];
        querySnapshot.forEach((doc) => {
            prodspecas.push(doc.data());
        });

        setPecasList(prodspecas);

        var domp = [];
        domp.push(
            <div className='card_prod card_pecas_destaque'>
                <div className='container_images'>
                    <img src={prodspecas[0].fotos[0]} alt={prodspecas[0].name}/>
                </div>
                <h1>{prodspecas[0].name}</h1>
                <p>100 vendidos</p>
                <Link to={'/produto/'+prodspecas[0].id+'/'+formatProductNameForURL(prodspecas[0].name)} className='btn_detail'>Ver Detalhes</Link>
            </div>
        )

        setProdP(domp);

        });


        // puxando produtos em destaque

        const qprodDestaque = query(collection(db, "product"), where("destaque", "==", true));
        const unsubscribe2 = onSnapshot(qprodDestaque, (querySnapshot) => {
        const prodsdestaque = [];
        querySnapshot.forEach((doc) => {
            prodsdestaque.push(doc.data());
        });

        setProdDestaqueList(prodsdestaque);

        });

        // puxando slides

            // definindo dispositivo
            var deviceNow = null;
            if(windowWidth >= 600){
                deviceNow = "desktop";
            }else{
                deviceNow = "mobile";
            }

        const qslideshome = query(collection(db, "slide"), where("device", "==", deviceNow));
        const unsubscribe3 = onSnapshot(qslideshome, (querySnapshot) => {
        const slideshome = [];
        querySnapshot.forEach((doc) => {
            slideshome.push(doc.data());
        });

        setSlides(slideshome);

        });

        const q = query(collection(db, "category"), where("type", "==", 1));
         const unsubscribe = onSnapshot(q, (querySnapshot) => {
         const categs = [];
         const arrPrincipal = [];
         querySnapshot.forEach((doc) => {

            var arrteste = doc.data();
             categs.push(doc.data());

             // puxando subs
             const qcategsubs = query(collection(db, "category"), where("type", "==", 2), where("categmae", "==", doc.data().id));
             const unsubscribecategsubs = onSnapshot(qcategsubs, (querySnapshot) => {
               const categsubs = [];
               querySnapshot.forEach((docc) => {
                  categsubs.push(docc.data());
               });

               arrteste['subs'] = categsubs;
               arrteste['numsubs'] = categsubs.length;
               
             });
             
             arrPrincipal.push(arrteste);

         });
 
         setCategHomeDom(arrPrincipal);
 
         });

      }, []);

      function formatProductNameForURL(productName) {
        // Remover acentuação
        const withoutAccents = productName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        // Remover caracteres especiais, exceto espaços e hifens
        const cleaned = withoutAccents.replace(/[^a-zA-Z0-9\s-]/g, '');
        // Substituir espaços por hífens
        const withHyphens = cleaned.replace(/\s+/g, '-');
        // Converter para minúsculas
        const urlFriendly = withHyphens.toLowerCase();
        return urlFriendly;
      }

      const controlSlideBox = (action,box) =>{

        if(box == "boxslideprod_1"){
            const elementP = document.getElementById(box);
            const stepElement = elementP.dataset.step;

            if(action == "prox"){
                var numAv = 310;
                var newStep = parseInt(stepElement)+parseInt(1);

                // atualizando step do container

                elementP.dataset.step = newStep;

                elementP.scrollLeft = newStep*numAv;
            }else if(action == "prev"){
                if(elementP.dataset.step != 0){
                    elementP.scrollLeft = -250;
                }
            }
        }

      }

      const renderCategoria = (item) =>{

        if(item.numsubs > 0){
            return (
                <li style={{display: 'flex'}}>
                    <div className='btncateghome'>
                        <Link to={'/categoria/'+item.id+'/'+formatProductNameForURL(item.name)}>
                            {item.name}
                        </Link>

                        <button className='dropdown-toggle dropdown-toggle-split' data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="visually-hidden" style={{paddingLeft: 10}}>Toggle Dropend</span>
                        </button>

                        <ul className="dropdown-menu dropcateghome">
                            {item.subs.map((itemm, indexx) => (
                                <li className='subcateg_container'>
                                    <div className='subcateg_img_content'>
                                        <img src={itemm.img} alt={itemm.name} width="60"/>
                                    </div>
                                    <div className='subcateg_text_content'>
                                        <b>{itemm.name}</b>
                                        <Link to='' style={{color: '#3483fa'}}>Ver Mais</Link>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </li>
            )
        }else{
            return(
                <li>    
                    <Link  to={'/categoria/'+item.id+'/'+formatProductNameForURL(item.name)}>
                        {item.name}
                    </Link>    
                </li>
            )
        }

      }

      const renderSubsCategoriaDestaque = (item) => {
        
        if(item.numsubs > 0){
            const subsdom = [];
            for(var i =0; i<item.numsubs; i++){
                subsdom.push(
                    <li><Link to={'categoria/'+item.subs[i].id+'/'+formatProductNameForURL(item.subs[i].name)}>{item.subs[i].name}</Link></li>
                )
            }
            return(
                <ul>{subsdom}</ul>
            )
        }
        
      }

      const renderImageSubsCategoriaDestaque = (item) => {
        if(item.numsubs > 0){
            const subsdomimg = [];
            for(var i =0; i<item.numsubs; i++){
                subsdomimg.push(
                    <img src={item.subs[i].img} alt={item.subs[i].name} loading='lazy'/>
                )
            }
            return(
                <div className='container_imagem_categoria'>{subsdomimg}</div>
            )
        }
      }

  return <div>

    <Header listadecateg={categorialista} config={config}/>

    <SEO
        title="KTX BRASIL - Máquinas e Ferramentas"
        description="Bem-vindo à página inicial da KTX BRASIL"
        image={imglink}
        url="https://ktxbrasil.com.br"
    />

    <div className='container'>
        <div className='slidecontainer'>
            <div className='menu_categ_slide'>
                <ul>
                {categhomedom.map((item, index) => (
                    renderCategoria(item)
                ))}
                </ul>
            </div>

            <div className='image_slide'>
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {slides.map((item, index) => (
                           <div className="carousel-item active">
                                <img src={item.img} className="itemslide" alt="..."/>
                            </div>
                        ))}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>

        <div className='beneficios_home'>
            <div className='row'>
                <div className='col-md-3'>
                    <section>
                        <i className='material-icons'>local_shipping</i>
                        <div>
                            <h2>Entrega Nacional</h2>
                            <p>todo território</p>
                        </div>
                    </section>
                </div>

                <div className='col-md-3'>
                    <section>
                        <i className='material-icons'>support_agent</i>
                        <div>
                            <h2>Suporte</h2>
                            <p>tire dúvidas</p>
                        </div>
                    </section>
                </div>

                <div className='col-md-3'>
                    <section>
                        <i className='material-icons'>verified_user</i>
                        <div>
                            <h2>100% Seguro</h2>
                            <p>sua compra garantida</p>
                        </div>
                    </section>
                </div>

                <div className='col-md-3'>
                    <section>
                        <i className='material-icons'>local_offer</i>
                        <div>
                            <h2>Super Preços</h2>
                            <p>descontos de até 60%</p>
                        </div>
                    </section>
                </div>
            </div>
        </div>

        <div className='container_vitrine'>
            <div className='title_vitrine'>
                <div className='row'>
                    <div className='col-md-2'>
                        <h3 style={{marginTop: 4}}>Produtos Em Destaques</h3>
                    </div>
                    <div className='col-md-9'>
                        <hr/>
                    </div>
                    <div className='col-md-1 container_btn_slide_vitrine'>
                        <button className='btn_slide_vitrine' data-box="boxslideprod_1" data-action="prev" onClick={() => controlSlideBox("prev", "boxslideprod_1")} style={{marginRight: '1vh'}}><i className='material-icons'>chevron_left</i></button>
                        <button className='btn_slide_vitrine' data-box="boxslideprod_1" data-action="prox" onClick={() => controlSlideBox("prox", "boxslideprod_1")}><i className='material-icons'>chevron_right</i></button>
                    </div>
                </div>
            </div>

            <section className='slide_produtos_vitrine row' data-step="0" id="boxslideprod_1" ref={boxslideproduct1}>

                {prodDestaquelist.map((item, index) => (
                    <div className='card_prod col-md-3'>
                        <div className='container_images'>
                            <img src={item.fotos[0]} alt={item.name}/>
                        </div>
                        <h1>{item.name.substring(0, 60)}...</h1>
                        <section className='container_avalia_categ'>
                          <i class="bi bi-star-fill"></i>
                          <i class="bi bi-star-fill"></i>
                          <i class="bi bi-star-fill"></i>
                          <i class="bi bi-star-fill"></i>
                          <i class="bi bi-star-fill"></i>
                        </section>
                        <p style={{textAlign: 'start', color: '#28a745', fontWeight: '500'}}>Disponivel</p>
                        <div style={{textAlign: 'left'}}>
                        <Link to={'/produto/'+item.id+'/'+formatProductNameForURL(item.name)} className='btn btn-secondary'>Ver Detalhes</Link>
                        </div>
                    </div>
                ))}
                
            </section>
        </div>

        <section className='boxbannerhome'>
            <div>
                <h3>Discos e Serras MTMCORTE</h3>
                <p>Uma linha completa com preços justos e muita durabilidade</p>
                <button>Ver Todos</button>
            </div>
        </section>

        <div className='container_vitrine'>
            <div className='title_vitrine'>
                <div className='row'>
                    <div className='col-md-2'>
                        <h3>Peças de Reposição</h3>
                    </div>
                    <div className='col-md-9'>
                        <hr/>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-5 col-sm-12'>
                    {prodpPe}
                </div>

                <div className='col-md-7 col-sm-12 pecasdest2'>


                        {prodPecasList.map((item, index) => (
                            <div key={index} className='card_pecas_destaque2'>
                                <div className='container_images'>
                                    <img src={item.fotos[0]} alt={item.name} width="100%"/>
                                </div>
                                <h1>{item.name}</h1>
                                <Link to={'/produto/'+item.id+'/'+formatProductNameForURL(item.name)} className='btn_consult'>Consulte</Link>
                            </div>
                        ))}   

                </div>
            </div>
        </div>
    </div>
    
    <Footer listadecateg={categorialista}/>
  </div>;
};

export default Home;