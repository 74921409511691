import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, onSnapshot, addDoc, doc, deleteDoc } from "firebase/firestore";
import { db, storage } from '../../../../../firebaseConfig';
import imageCompression from 'browser-image-compression';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage";


const CategoriaModal = () => {

    const [ncateg, setNcateg] = useState(0);
    const [categdom, setCategDom] = useState(null);
    const [subvis, setSubVis] = useState('none');
    const [addcategvis, setaddcategvis] = useState('none');
    const [categlist, setCategList] = useState([]);

        const q = query(collection(db, "category"), where("type", "==", 1));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const categs = [];
        querySnapshot.forEach((doc) => {
            categs.push(doc.data());
        });
        setNcateg(categs.length);


        if(categs.length == 0){
            setCategDom(<li>Nenhuma Categoria Criada...</li>);
        }else{
            setCategList(categs);
        }

        });

        const [typeCategNew, setTypeCategNew] = useState(null);

        const changeCategTp = (event) =>{
            if(event.target.value == 2){
                setSubVis('block');
            }

            setTypeCategNew(event.target.value);
        }

        const openAddCateg = () => {
            setaddcategvis('block');
        }

        // categ new info

        const [namecategnew, setNameCategNew] = useState(null);
        const [descCategNew, setDescCategNew] = useState(null);

        const nameCategSet = (event) => {
            setNameCategNew(event.target.value);
        }

        const descCategSet = (event) => {
            setDescCategNew(event.target.value);
        }

        const saveCategNew = async () => {

                function gerarStringUnica() {
                    // Obtém o timestamp atual em milissegundos
                    const timestamp = Date.now().toString(36); // Converte o timestamp para uma string em base 36 (0-9a-z)
                    
                    // Gera uma string aleatória de letras
                    const letras = 'abcdefghijklmnopqrstuvwxyz';
                    let stringAleatoria = '';
                    for (let i = 0; i < 5; i++) { // Gera uma string de 5 letras aleatórias
                        stringAleatoria += letras[Math.floor(Math.random() * letras.length)];
                    }
                    
                    // Combina o timestamp com a string aleatória
                    const stringUnica = timestamp + stringAleatoria;
                    
                    return stringUnica;
                }
           
                const docRef = addDoc(collection(db, "category"), {
                    name: namecategnew,
                    desc: descCategNew,
                    type: 1,
                    loja: 'ktx',
                    id: gerarStringUnica()
                 });

                 alert('categoria salva com sucesso');

                 setaddcategvis('none');

        }

        const defineTpCateg = (tp) => {
            if(tp == 1){
                return "Categoria Mãe";
            }else{
                return "Sub-Categoria";
            }
        }

        const removeCateg = async(categ) =>{

            if(window.confirm("Deseja Realmente Excluir Essa Categoria? Lembrando que todas as Sub Categoria serão excluidas!")){
                const qcategdel = query(collection(db, "category"), where("id", "==", categ));
                const unsubscribecatgdel = onSnapshot(qcategdel, (querySnapshot) => {
                var categdocid = null;
                const arrcateginfo = [];
    
                querySnapshot.forEach((doc) => {
                    categdocid = doc.id;
                });
    
                if(categdocid){

                    // checando todas as sub

                    const qchecksubs = query(collection(db, "category"), where("type", "==", 2), where("categmae", "==", categ));
                    const unsubscribechecksubs = onSnapshot(qchecksubs, (querySnapshot) => {
                    const arrInfoSubs = [];

                    querySnapshot.forEach((doc) => {
                        arrInfoSubs.push(doc.id);
                    });
                    
                    // excluindo todas as subs 

                    for(var i=0; i<arrInfoSubs.length; i++){
                        deleteDoc(doc(db, 'category', arrInfoSubs[i]));
                    }
                    
                    });

                    // excluindo categoria
                    deleteDoc(doc(db, 'category', categdocid));
                    alert("Categoria e Sub-Categoria Excluidas com Sucesso!");
                }
                
                });
            }

        }
        const removeSubCateg = async(event) =>{
            const dataTest = event.target.getAttribute('data-test');


            if(window.confirm("Deseja Realmente Excluir Essa Sub Categoria?")){
                const qsubdel = query(collection(db, "category"), where("id", "==", dataTest));
                const unsubscribesubdel = onSnapshot(qsubdel, (querySnapshot) => {
                var categdocid = null;
                const categsubinfo = [];
    
                querySnapshot.forEach((doc) => {
                    categdocid = doc.id;
                    categsubinfo.push(doc.data());
                });
    
                if(categdocid){

                    // excluindo imagem da categoria

                    if(categsubinfo[0].img){

                        const fileUrl = categsubinfo[0].img;

                        // Extraia o caminho do arquivo da URL
                        const filePath = fileUrl.split("/o/")[1].split("?")[0].replace(/%2F/g, "/");

                        // Crie uma referência para o arquivo
                        const fileRef = ref(storage, filePath);

                        // Delete o arquivo
                        deleteObject(fileRef);

                    }

                    // excluindo subcategoria
                    deleteDoc(doc(db, 'category', categdocid));
                    alert("Sub-Categoria Excluida com Sucesso!");
                }

                });
            }
        }

        // subcategs

        const [namesubcateg, setNameSubCateg] = useState(null);
        const defineNameSubCateg = (e) =>{
            setNameSubCateg(e.target.value);
        }

        const [descsubcateg, setDescSubCateg] = useState(null);
        const defineDescSubCateg = (e) =>{
            setDescSubCateg(e.target.value);
        }

        const [subcateglist, setSubCategList] = useState([]);
        const [numsubcateglist, setNumSubCategList] = useState(0);
        const [subcateglistdom, setsubcategdom] = useState(null);
        const [imagesubcateg, setimagesubcateg] = useState(null);
        const [imagesubdom, setImageSubDom] = useState(null);

        const saveSubCateg = async(categmae) =>{
            function gerarStringUnica() {
                // Obtém o timestamp atual em milissegundos
                const timestamp = Date.now().toString(36); // Converte o timestamp para uma string em base 36 (0-9a-z)
                
                // Gera uma string aleatória de letras
                const letras = 'abcdefghijklmnopqrstuvwxyz';
                let stringAleatoria = '';
                for (let i = 0; i < 5; i++) { // Gera uma string de 5 letras aleatórias
                    stringAleatoria += letras[Math.floor(Math.random() * letras.length)];
                }
                
                // Combina o timestamp com a string aleatória
                const stringUnica = timestamp + stringAleatoria;
                
                return stringUnica;
            }


            var uri = imagesubcateg[0].url;
            var urlImg = null;

            if(uri){

                // Criar um nome de arquivo único
                const filename = uri.substring(uri.lastIndexOf('/') + 1);
                const response = await fetch(uri);
                const blob = await response.blob();

                // Referência ao arquivo no Firebase Storage
                const storageRef = ref(storage, filename);

                // Upload do arquivo para o Firebase Storage
                await uploadBytes(storageRef, blob);

                // Obter a URL de download
                urlImg = await getDownloadURL(storageRef);

            }else{
                urlImg = null;
            }

            
       
            const docRef = addDoc(collection(db, "category"), {
                name: namesubcateg,
                desc: descsubcateg,
                type: 2,
                loja: 'ktx',
                id: gerarStringUnica(),
                categmae: categmae,
                img: urlImg
             });

             alert('sub-categoria salva com sucesso');
        }

        const requestSubCateg = async(categmae) =>{

            setsubcategdom(null);

            const qsub = query(collection(db, "category"), where("categmae", "==", categmae), where("type", "==", 2));
            const unsubscribesub = onSnapshot(qsub, (querySnapshot) => {
            const categsubs = [];
            querySnapshot.forEach((doc) => {
                categsubs.push(doc.data());
            });
            setNumSubCategList(categsubs.length);
            const domarr = [];

            if(categsubs.length == 0){
                domarr.push(

                    <li className='card' style={{padding: 10}}>
                        <div className='row'>
                            <div className='col-md-12 col-sm-12'>
                                <b>Nenhuma Sub Categoria encontrada</b>
                            </div>
                        </div>
                    </li>

                );
            }else{
                setSubCategList(categsubs);

                for(var i = 0; i < categsubs.length; i++){
                    domarr.push(
    
                        <li className='card' style={{padding: 10}}>
                            <div className='row'>
                                <div className='col-md-6 col-sm-6'>
                                    <b>{categsubs[i].name}</b>
                                </div>
    
                                <div className='col-md-6 col-sm-6' style={{textAlign: 'right'}}>
                                    <button className='btn btn-danger' data-test={categsubs[i].id} onClick={removeSubCateg}>Remover</button>
                                </div>
                            </div>
                        </li>
    
                    );
                }
            }

            setsubcategdom(domarr);

            });
        }

        const handleChange = async(e) => {
            if (e.target.files[0]) {

            var file = e.target.files[0];

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 116,
                useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);
              const arr = [{image: compressedFile, url: URL.createObjectURL(compressedFile)}];
              setimagesubcateg(arr);

              setImageSubDom(<img src={URL.createObjectURL(compressedFile)} width="80"/>);
            }
        };

        const handleChange2 = async(e,categ) => {
            if (e.target.files[0]) {

            var file = e.target.files[0];

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 116,
                useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);
              const arr = [{image: compressedFile, url: URL.createObjectURL(compressedFile)}];
              setimagesubcateg(arr);

              setImageSubDom(<img src={URL.createObjectURL(compressedFile)} width="80"/>);
            }
        };


  return <div className='container_painel_admin'>
    
    <h1>Categorias ({ncateg})</h1>

    <button className='btn btn-primary' onClick={openAddCateg}>Adicionar Categoria</button>

    <div className='container_Addcateg' style={{display: addcategvis}}>
        <div className='row'>
            <div className='col-md-4'>
                <label for="namecateg">Nome Da Categoria</label>
                <input type='text' id="namecateg" onChange={nameCategSet} className='form-control'/>
            </div>
            <div className='col-md-4'>
                <label for="desccateg">Descriçao Da Categoria</label>
                <input type='text' id="desccateg" onChange={descCategSet} className='form-control'/>
            </div>
            <div className='col-md-4'>
                <label for="typecateg">Tipo Da Categoria</label>
                <select onChange={changeCategTp} className="form-select" aria-label="Default select example">
                    <option selected>Selecione o tipo da categoria</option>
                    <option value="1">Categoria Mão</option>
                    <option value="2">Sub-Categoria</option>
                </select>
            </div>

            <div className='col-md-4' style={{display: subvis}}>
                <label for="typecateg">Selecione A Categoria Mãe</label>
                <select className="form-select" aria-label="Default select example">
                    <option selected>Selecione o tipo da categoria</option>
                    <option value="1">Categoria Mão</option>
                    <option value="2">Sub-Categoria</option>
                </select>
            </div>

            <div className='col-md-4'>
            </div>

            <div className='col-md-4' style={{textAlign: 'right', paddingTop: '2vh'}}>
                <button className='btn btn-primary' onClick={saveCategNew}> Salvar </button>
            </div>
        </div>
    </div>

    <ul style={{maxHeight: '50vh', overflowY: 'scroll'}}>
        {categlist.map((item, index) => (
          <li className='listcategmodal' key={index}>
            <h2 style={{fontSize: 18}}>{item.name}</h2>
            <section>
                <b style={{marginRight: 5}}>{defineTpCateg(item.type)}</b>
                <button className='btn btn-danger' onClick={() => removeCateg(item.id)}>Remover</button>
                <button className='btn btn-warning' data-bs-toggle="collapse" data-bs-target={'#'+item.id} aria-expanded="false" aria-controls={item.id} onClick={() => requestSubCateg(item.id)}>Detalhes</button>
            </section> 
            
            <div className='container_categ_more_info'>
                <div className="collapse multi-collapse" id={item.id}>
                    <div className="card card-body">

                        <p>Informações:</p>

                        <div className='row'>
                            <div className='col-md-4 col-sm-6'>
                                <label>Imagem:</label>
                                {item.img === undefined?(
                                    <img src='https://i.imgur.com/kFkoGwy.jpg' alt={item.name} width="60"/>
                                ):(
                                    <img src={item.img} alt={item.name} width="60"/>
                                )}
                                <input type='file' className='form-control' accept="image/*" onChange={() => handleChange2(this, item.id)}/>
                            </div>

                            <div className='col-md-4 col-sm-6'>
                                <label>Nome:</label>
                                <input type='text' className='form-control' value={item.name}/>
                            </div>

                            <div className='col-md-4 col-sm-12'>
                                <label>Descrição:</label>
                                <textarea className='form-control' value={item.desc}></textarea>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 col-sm-12' style={{textAlign: 'right', marginTop: 15}}>
                                <button className='btn btn-primary'>Salvar</button>
                            </div>
                        </div>

                        <hr></hr>

                        <p>Sub-Categorias ({numsubcateglist})</p>

                        <div className='row'>
                            <div className='col-md-12'>
                                <button className='btn btn-primary' data-bs-toggle="collapse" data-bs-target={"#sub_"+item.id} aria-expanded="false" aria-controls={"#sub_"+item.id}>Adicionar SubCategoria</button>
                            </div>

                            <div className='col-md-12'>
                                <div class="collapse" id={"sub_"+item.id}>
                                    <div class="card card-body">
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                {imagesubdom}
                                                <label>Imagem Da Sub-Categoria</label>
                                                <input type="file" className='form-control' accept="image/*" onChange={handleChange}/>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Nome da subcategoria</label>
                                                <input type="text" className='form-control' placeholder='insira o nome da sub-categoria' value={namesubcateg} onChange={defineNameSubCateg}/>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Descrição da subcategoria</label>
                                                <textarea className='form-control' onChange={defineDescSubCateg} value={descsubcateg}></textarea>
                                            </div>
                                            <div className='col-md-12' style={{textAlign: 'center'}}>
                                                <button className='btn btn-primary' onClick={() => saveSubCateg(item.id)}>Salvar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ul style={{listStyle: 'none', marginTop: 10, paddingLeft: 0}}>
                            {subcateglistdom}
                        </ul>
                    </div>
                </div>
            </div>

          </li>
        ))}
    </ul>

  </div>;
};

export default CategoriaModal;