import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png';
import { auth, db, storage } from '../../../firebaseConfig';
import { onAuthStateChanged, signOut, getAuth } from 'firebase/auth';
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage";

const Config = () => {

    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [config, setConfig] = useState([]);

    // variaveis gerais

    const [fantasia, setFantasia] = useState("");
    const [meta, setMeta] = useState("");
    const [logomarca, setLogomarca] = useState("");
    const [celular, setCel] = useState("");
    const [telefone, setTel] = useState("");
    const [email, setEmail] = useState("");
    const [youtube, setYoutube] = useState("");
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [logo, setLogo] = useState("");
    const [selectedLogo, setSelectedLogo] = useState(null);

    useEffect(() => {
        document.title = "KTX BRASIL ADM";

        // puxando configurações da loja
        const unsub = onSnapshot(doc(db, "config", "ktx"), (doc) => {
            setConfig(doc.data());

            setFantasia(doc.data().name);
            setMeta(doc.data().meta);
            setCel(doc.data().contatos.celular);
            setTel(doc.data().contatos.telefone);
            setEmail(doc.data().contatos.email);
            setYoutube(doc.data().contatos.youtube);
            setFacebook(doc.data().contatos.facebook);
            setInstagram(doc.data().contatos.instagram);

            if(doc.data().logo.length == 0){
                setLogo("https://i.imgur.com/kFkoGwy.jpg");
            }else{
                setLogo(doc.data().logo);
            }
            
        });
      }, []);


    const sair = () =>{
        signOut(auth).then(() => {
          console.log("Deslogado");
        }).catch((error) => {
          console.log("error ao deslogar");
        });
    }

    const save = async() => {
        if(window.confirm("Deseja Realmente salvar as configurações?")){
            const qsave = doc(db, "config", "ktx");

            await updateDoc(qsave, {
                name: fantasia,
                meta: meta,
                'contatos.celular': celular,
                'contatos.telefone': telefone,
                'contatos.email': email,
                'contatos.youtube': youtube,
                'contatos.facebook': facebook,
                'contatos.instagram': instagram
            });

            // salvando imagem se necessario

            if(selectedLogo != null){
                const filename = selectedLogo.substring(selectedLogo.lastIndexOf('/') + 1);
                const response = await fetch(selectedLogo);
                const blob = await response.blob();

                // Referência ao arquivo no Firebase Storage
                const storageRef = ref(storage, filename);

                // Upload do arquivo para o Firebase Storage
                await uploadBytes(storageRef, blob);

                // Obter a URL de download
                const url = await getDownloadURL(storageRef);

                // atualizando

                const qsaveimg = doc(db, "config", "ktx");

                await updateDoc(qsaveimg, {
                    logo: url
                });

                // excluindo imagem antiga se houver


                if(config.logo.length > 0){
                    try{
                        const storageRef = ref(storage, config.logo);
                        await deleteObject(storageRef);
        
                    }catch(error){
                        alert("Ocorreu um problema ao deletar a imagem do cloud");
                    }
                }

            }

            alert("Configurações salvas com sucesso!");
        }
    }

    const uploadImage = async(uri) =>{
        if (!uri) return;

        // Criar um nome de arquivo único
        const filename = uri.substring(uri.lastIndexOf('/') + 1);
        const response = await fetch(uri);
        const blob = await response.blob();

        // Referência ao arquivo no Firebase Storage
        const storageRef = ref(storage, filename);

        // Upload do arquivo para o Firebase Storage
        await uploadBytes(storageRef, blob);

        // Obter a URL de download
        const url = await getDownloadURL(storageRef);

        return url;
    }

    const handleChange = e => {
        if (e.target.files[0]) {
    
          const arr = [{image: e.target.files[0], url: URL.createObjectURL(e.target.files[0])}];
          setSelectedLogo(arr[0].url);
          setLogo(arr[0].url);
        }
      };


  return <div className='container_painel_admin'>
    <nav className="navbar" style={{backgroundColor: '#FFD333'}}>
        <div className="container-fluid">
            <a className="navbar-brand">KTX Brasil</a>
            <button className='btn btn-primary' onClick={sair}>Sair</button>
        </div>
    </nav>

    <button className='btn-save-float' title="Salvar Alterações" onClick={() => save()}><i class="bi bi-save"></i></button>

    <div className='container container_padmin' style={{marginTop: 20}}>
        <nav aria-label="breadcrumb" style={{marginTop: 10}}>
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/admin">Inicio</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Configurações</li>
            </ol>
        </nav>
        <h1>Configurações</h1>
        <div className='row'>
            <div className='col-md-12'>
                <label>Logomarca:</label>
                <div className='row'>
                    <div className='col-md-4'>
                        <b>Foto atual:</b>
                        <br></br>
                        <img src={logo} alt="logomarca" width="120"/>
                    </div>
                    <div className='col-md-4'>
                        <b>Selecionar Nova Imagem:</b>
                        <input type="file" className='form-control' accept="image/png, image/gif, image/jpeg" onChange={handleChange}/>
                    </div>
                </div>
            </div>
        </div>
        <div className='row' style={{marginTop: 20}}>
            <div className='col-md-4 col-sm-12'>
                <label>Nome Fantasia:</label>
                <input type="text" className='form-control' placeholder='nome fantasia...' value={fantasia} onChange={(e) => setFantasia(e.target.value)}/>
            </div>
        </div>
        <div className='row' style={{marginTop: 20}}>
            <div className='col-md-4 col-sm-12'>
                <label>Meta Descrição:</label>
                <textarea className='form-control' value={meta} onChange={(e) => setMeta(e.target.value)}></textarea>
            </div>
        </div>
        <div className='row' style={{marginTop: 20}}>
            <div className='col-md-12 col-sm-12'>
                <label>Contatos</label>
                <div className='row' style={{marginTop: 20}}>
                    <div className='col-md-4 col-sm-12'>
                        <label>Celular</label>
                        <input type='number' className='form-control' placeholder='Insira o numero de celular...' value={celular} onChange={(e) => setCel(e.target.value)}/>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Telefone</label>
                        <input type='number' className='form-control' placeholder='Insira o numero de telefone...' value={telefone} onChange={(e) => setTel(e.target.value)}/>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>E-mail</label>
                        <input type='email' className='form-control' placeholder='Insira o e-mail principal...' value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                </div>

                <label style={{marginTop: 20, fontWeight: 'bold'}}>Social</label>
                <div className='row' style={{marginTop: 20}}>
                    <div className='col-md-4 col-sm-12'>
                        <label>Youtube</label>
                        <input type='text' className='form-control' placeholder='Insira o endereço do video do youtube...' onChange={(e) => setYoutube(e.target.value)} value={youtube}/>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Facebook</label>
                        <input type='text' className='form-control' placeholder='Insira o endereço do perfil...' value={facebook} onChange={(e) => setFacebook(e.target.value)}/>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Instagram</label>
                        <input type='text' className='form-control' placeholder='Insira o endereço do perfil...' value={instagram} onChange={(e) => setInstagram(e.target.value)}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>;
};

export default Config;