import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../header/logo.png';
import { collection, query, where, onSnapshot, doc } from "firebase/firestore";
import { db } from '../../../firebaseConfig';

const Footer = ({listadecateg}) => {
    const [logomarcafooter, setLogomarcafooter] = useState(null);
    const [celularfooter, setCelfooter] = useState(null);
    const [fixofooter, setFixofooter] = useState(null);
    const [emailfooter, setEmailfooter] = useState(null);
    const [youtubevideo, setYoutubevideo] = useState(null);

    function formatProductNameForURLMOB(productName) {
        // Remover acentuação
        const withoutAccents = productName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        // Remover caracteres especiais, exceto espaços e hifens
        const cleaned = withoutAccents.replace(/[^a-zA-Z0-9\s-]/g, '');
        // Substituir espaços por hífens
        const withHyphens = cleaned.replace(/\s+/g, '-');
        // Converter para minúsculas
        const urlFriendly = withHyphens.toLowerCase();
        return urlFriendly;
      }

      useEffect(() => {

        const con = onSnapshot(doc(db, "config", "ktx"), (doc) => {
            
            setLogomarcafooter(doc.data().logo);
            setCelfooter(doc.data().contatos.celular);
            setFixofooter(doc.data().contatos.telefone);
            setEmailfooter(doc.data().contatos.email);
            setYoutubevideo(doc.data().contatos.youtube);
        });
    
      }, []);
  return <footer>
    <hr/>
    <div className='container'>
        <div className='row'>
            <div className='col-md-4 context1footer col-sm-12'>
                <h2 className='title_loja_footer'>KTX BRASIL Máquinas e Equipamentos</h2>
                <p>Fundada em 2016, a KTX BRASIL oferece máquinas, equipamentos e ferramentas para diversos setores.</p>
                <ul className='listcontenttex1foo'>
                    <li> <i className='material-icons'>travel_explore</i> <p>Rua José Versolato, 111 - CEP 09750-730 - São Bernardo do Campo - São Paulo</p> </li>
                    <li> <i className='material-icons'>mail</i> <p>{emailfooter}</p></li>
                    <li> <i className='material-icons'>phone</i> <p>Fixo: {fixofooter} WhatsApp: {celularfooter}</p></li>
                    <li> <i className='material-icons'>exit_to_app</i> <p>Aberto dás 8:00 às 17:00 Segunda a Sexta Feira</p></li>
                </ul>
            </div>
            <div className='col-md-2 col-sm-6'>
                <h2 className='title_loja_footer'>Informações</h2>
                <ul>
                    <li> <Link to='/empresa'>A Empresa</Link> </li>
                    <li> <Link to='/pagamento'>Meios de Pagamentos</Link> </li>
                    <li> <Link to='/garantia'>Garantia</Link> </li>
                    <li> <Link to='/contatos'>Meios de contato</Link> </li>
                </ul>
            </div>
            <div className='col-md-2 col-sm-6'>
                <h2 className='title_loja_footer'>Produtos</h2>
                <ul>
                {listadecateg.map((item, index) => (
                    <li><Link style={{fontWeight: '500', fontSize: 18}} to={'/categoria/'+item.id+'/'+formatProductNameForURLMOB(item.name)}>{item.name}</Link></li>
                ))}
                </ul>
            </div>
            <div className='col-md-4 col-sm-12 ytmobile'>
                <h2 className='title_loja_footer'>Acesse nosso youtube</h2>
                <iframe width="320" height="210" src={youtubevideo} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                <p>Redes Sociais</p>
            </div>
        </div>

        <div className='row'>
            <div className='subfooter col-sm-12'>
                <p>KTX BRASIL - Máquinas e Ferramentas - Todos os Direitos Reservados</p>
                <ul>
                    <li></li>
                </ul>
            </div>
        </div>
    </div>
  </footer>;
};

export default Footer;