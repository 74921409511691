import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useLocation } from 'react-router-dom';
import Header from '../../contents/header';
import Footer from '../../contents/footer';



const Meiospag = ({categorialista}) => {
    document.title = "KTX BRASIL - Meios de Pagamentos";
    return(
    <div>
        <Header listadecateg={categorialista}/>

        <div className='container'>
            <nav aria-label="breadcrumb" style={{marginTop: 10}}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to='/'>Inicio</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Pagamento</li>
                </ol>
            </nav>

            <h1>Meios De Pagamentos</h1>
            
            <div className='content_garantia'>
                <img src="https://i.imgur.com/zs9gOri.jpeg" alt="Meios de pagamentos ktx brasil"/>
            </div>

        </div>

        <Footer listadecateg={categorialista}/>
    </div>
)};

export default Meiospag;