import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, onSnapshot, addDoc, doc, deleteDoc } from "firebase/firestore";
import { db, storage, auth } from '../../../firebaseConfig';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage";
import { onAuthStateChanged, signOut, getAuth } from 'firebase/auth';

const Orcamento = () => {

    useEffect(() => {
        document.title = "KTX BRASIL ADM - Orçamentos";
    }, []);


  const sair = () =>{
    signOut(auth).then(() => {
      console.log("Deslogado");
    }).catch((error) => {
      console.log("error ao deslogar");
    });
  }



  return <div className='container_painel_admin'>
    <nav className="navbar" style={{backgroundColor: '#FFD333'}}>
        <div className="container-fluid">
            <a className="navbar-brand">KTX Brasil</a>
            <button className='btn btn-primary' onClick={sair}>Sair</button>
        </div>
    </nav>

    <div className='container'>

      <nav aria-label="breadcrumb" style={{marginTop: 10}}>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/admin">Inicio</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Orçamentos</li>
        </ol>
      </nav>

    </div>

  </div>;
};

export default Orcamento;