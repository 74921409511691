import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useLocation } from 'react-router-dom';
import Header from '../../contents/header';
import Footer from '../../contents/footer';



const Garantia = ({categorialista}) => {
    document.title = "KTX BRASIL - Garantia";
    return(
    <div>
        <Header listadecateg={categorialista}/>

        <div className='container'>
            <nav aria-label="breadcrumb" style={{marginTop: 10}}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to='/'>Inicio</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Garantia</li>
                </ol>
            </nav>

            <h1>Garantia</h1>
            
            <div className='content_garantia'>
                <h2>COMPRE SEM LIMITES, NÓS CUIDAMOS DE VOCÊ</h2>
                <p>Não recebeu o que esperava? Se arrependeu? Devolvemos seu dinheiro.</p>
                <p>Com o programa de Compra Garantida, seu dinheiro está sempre protegido.</p>
                <br></br>
                <p>Com a BRFER Máquinas e Ferramentas você pode comprar com tranquilidade, se

houver algum problema ou se não receber o produto que comprou,

devolveremos o dinheiro. Realizamos a cobertura total do seu produto, até

que ele seja entregue para você.</p>
            <h2>TROCA  E DEVOLUÇÃO GRÁTIS
- DE ACORDO COM O CÓDIGO DE DEFESA DO CONSUMIDOR, SÃO 7 DIAS DE COBERTURA</h2>
            <p>partir do recebimento, caso você se arrependa da compra do produto, ou o
mesmo apresente algum problema.</p>
            <br></br>
            <p>Art. 49, CDC: O Consumidor pode desistir do contrato, no prazo de

7 dias a contar de sua assinatura ou do ato de recebimento do produto

ou serviço, sempre que a contratação de fornecimento de produtos e

serviços ocorrer fora do estabelecimento, especialmente por telefone

ou a domicilio.</p>
            <br></br>
            <b>Não cobrimos</b>
            <ul>
                <li>Produto com sinais de mau uso.</li>
                <li>Instalação inadequada,ocasionando problemas na funcionalidade.</li>
            </ul>
            </div>

        </div>

        <Footer listadecateg={categorialista}/>
    </div>
)};

export default Garantia;